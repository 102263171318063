import React from 'react';
import htmlParse from 'html-react-parser';
import { parse } from 'marked';
import FooterMenu from '../general/FooterMenu';
import Footer from '../general/Footer';
import Spinner from '../general/Spinner';

function Eula({ data, footer }) {
  const section = data?.attributes?.Sections[0];

  if (!section) {
    return (
      <Spinner />
    );
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="external-page">
        <div className="faq-content">
          <h1 className="title">
            {section.header && htmlParse(parse(section.header))}
          </h1>
          <div className="questions">
            {section.content && htmlParse(parse(section.content))}
          </div>
        </div>
      </div>
      <FooterMenu data={footer} />
      <div style={{ position: 'relative' }}>
        <Footer data={footer} />
      </div>
    </div>
  );
}

export default Eula;
