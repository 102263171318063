import { Badge } from '../general/Badge';

export const NFTMultiplierBadge = ({ multiplier, isHeader, cn }) => {
  if (!multiplier) return null;

  return (
    <Badge cn={`bg-[#7B36C9] text-white ${cn}`}>
      {multiplier}
      <span className='text-base'>x</span>
      {!isHeader && ' Multiplier'}
    </Badge>
  );
};

export const NFTMaxMultiplierBadge = ({ multiplier }) => (
  <div className='flex'>
    <Badge cn={'bg-[#7B36C9] text-white rounded-r-none py-0.5'}>
      {multiplier}
      <span className='text-base'>x</span>
    </Badge>
    <Badge cn={'bg-white text-[#7B36C9] rounded-l-none py-0.5'} >
      <span className='text-base'> MAX</span>
    </Badge>
  </div>
);
