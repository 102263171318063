import { createSlice } from '@reduxjs/toolkit';

const localStorageKey = 'sound';
const isEnabledSound = () => {
  const value = window.localStorage.getItem(localStorageKey);

  return !value || value === 'true';
};

const initialState = {
  sound: isEnabledSound(),
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    disableSounds: (state) => {
      state.sound = false;
      window.localStorage.setItem(localStorageKey, 'false');
    },
    enableSounds: (state) => {
      state.sound = true;
      window.localStorage.setItem(localStorageKey, 'true');
    },
  },
});

// Action creators are generated for each case reducer function
export const { disableSounds, enableSounds } = settingsSlice.actions;
export const settingsSelector = (state) => state.settings;

export default settingsSlice.reducer;
