import { truncateLongString } from '../../../utils';

export const SignInBox = ({
  isAccountLinked,
  disconnectHandler,
  logoUrl,
  linkUrl,
  email,
  label,
}) => (
  <div
    className={'shad flex w-full min-w-[300px] max-w-[350px] rounded-lg bg-white p-4 text-black md:mb-0 md:p-6'}
  >
    <div className="mr-4 h-auto w-9">
      <img
        className="w-full min-w-9"
        src={logoUrl}
        alt="discordIcon"
      />
    </div>
    <div className="flex flex-col justify-between font-objektiv">
      <div className="mb-4 text-xl">{label}</div>
      {isAccountLinked
        ? (
          <div>
            <p className="text-sm">{truncateLongString(email)}</p>
            <div className="flex items-end text-sm">
              <button
                onClick={disconnectHandler}
                className="text-xs text-blue-400 underline sm:text-sm"
              >
                Unlink Account
              </button>
              <div className="mx-1 text-black">|</div>
              <a
                href={linkUrl}
                className="text-xs text-blue-400 underline sm:text-sm"
              >
                Change Account
              </a>
            </div>
          </div>
        ) : (
          <a
            href={linkUrl}
            className="text-xs text-blue-400 underline sm:text-sm"
          >
            Link Account
          </a>
        )}
    </div>
  </div>
);
