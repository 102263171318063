export const DEFAULT_ERROR_MSG = 'Link Expired';
export const PIXION_LINK = 'https://pixiongames.com';
export const CDN_URL = 'https://cdn.fableborne.com/fab';
export const CONTENT_URL = `${CDN_URL}/content/`;
export const INNER_RADIUS = 100;
export const OUTER_RADIUS = 200;
export const DATE_SUFFIX_MAP = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};
export const DATE_SUFFIX_EXCEPTIONS = [11, 12, 13];
export const TABLET_WIDTH = 1280;
export const MOBILE_WIDTH = 768;
export const APPLE_DEVICES = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
  'Mac68K',
  'MacPPC',
  'MacIntel',
];
export const MAX_LEADERBOARD_LENGTH = 20;
export const DEFAULT_TOP_REWARDED = 10000;
export const DEFAULT_MAX_MULTIPLIER = 15;

export const QUEST_STATE_MAPPER = {
  CLAIMED: 'DONE',
  COMPLETED: 'CLAIM',
  ACTIVE: 'GO',
};
