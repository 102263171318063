export const SeasonStateEnum = {
  COMING: 'COMING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const TournamentStateEnum = {
  COMING: 'COMING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const QuestStateEnum = {
  CLAIMED: 'CLAIMED',
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
};

export const GameStateEnum = {
  GAME_LIVE: 'game_live',
  BOSS_DEFEATED: 'boss_defeated',
  GAME_END: 'end_of_game',
  SEASON_END: 'end_of_season',
  GAME_START: ' game_is_starting',
};

export const WalletConnectStateEnum = {
  CONNECT_WALLET: 'CONNECT WALLET',
  WRONG_NETWORK: 'WRONG NETWORK',
  CONNECT_ANOTHER_WALLET: 'CONNECT ANOTHER WALLET',
};

export const LeaderboardTypeEnum = {
  SOCIAL: 'SOCIAL',
  REFERRAL: 'REFERRAL',
};

export const BossEnum = {
  cleaver: 'cleaver',
  rocket: 'rocket',
  knight: 'knight',
};

export const VideosEnum = {
  idle: 'idle',
  hit: 'hit',
  death: 'death',
  recharge: 'recharge',
};
