import isValidVariable from './isValidVariable';

const outputImageWidth = (media) => {
  let value = 0;
  if (isValidVariable(media)) {
    if (isValidVariable(media.width)) {
      value = media.width;
      if (isValidVariable(media.formats)) {
        if (isValidVariable(media.formats.small)) {
          if (isValidVariable(media.formats.small.width)) {
            value = media.formats.small.width;
          }
        }
      }
    }
  }
  return value;
};

export default outputImageWidth;
