import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleDashboardRequest } from '../context/dashboard/handleDashboardRequest';
import { setIsLoggedIn } from '../redux/slices/navbar.slice';

const env = process.env.REACT_APP_NODE_ENV;

export const useAuthRequired = ({ strict } = { strict: true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const {
        status,
        data: fetchedData,
      } = await handleDashboardRequest();

      dispatch(setIsLoggedIn(status === 200));

      if (!strict) return;

      if (status === 401 || !fetchedData) {
        navigate('/sign-in');
      } else if (!fetchedData?.username && !fetchedData?.legacy) {
        navigate('/create-username');
      } else if (fetchedData?.legacy && !fetchedData?.username && env !== 'local') {
        navigate('/legacy-create-username');
      }
    })();
  }, []);
};
