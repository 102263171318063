import SignUp from '../components/sections/SignUp';
import Trailer from '../components/sections/Trailer';
import WhyFableborne from '../components/sections/WhyFableborne';
import Nft from '../components/sections/Nft';
import About from '../components/sections/About';
import Social from '../components/sections/Social';
import FooterMenu from '../components/general/FooterMenu';
import Footer from '../components/general/Footer';
import { useAuthRequired } from '../hooks/useAuthRequired';
import { useContent } from '../services/api/content';
import { websiteContent } from '../constants/content';
import Spinner from '../components/general/Spinner';

const sectionsUrls = [
  websiteContent.nft,
  websiteContent.virtues,
  websiteContent.footer,
  websiteContent.investorsLogos,
  websiteContent.social,
];

function FableborneHomepage() {
  useAuthRequired({ strict: false });

  const {
    isLoading, data: [
      nft,
      virtues,
      footer,
      investorsLogos,
      social,
    ],
  } = useContent(sectionsUrls);

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <div className="relative top-[60px] m-auto block max-w-[1920px] overflow-hidden border-0 p-0">
      <div className="relative" id="home">
        <SignUp />
        <Trailer />
        <WhyFableborne data={virtues} />
        <Nft data={nft} />
        <About data={investorsLogos} />
        <Social data={social} />
        <FooterMenu data={footer} />
        <Footer data={footer} />
      </div>
    </div>
  );
}

export default FableborneHomepage;
