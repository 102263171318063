import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global-styles.scss';
import './index.css';
import 'animate.css/animate.min.css';
import TagManager from 'react-gtm-module';
import { getCookieConsentValue } from 'react-cookie-consent';
import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-57VZBBFS',
};
if (getCookieConsentValue('FableborneCookiesSetNow') === 'true') {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);
