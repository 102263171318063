import { Box } from '../shared/Box';
import { urls } from '../../../constants/urls';
import { SignInBox } from '../shared/SignInBox';
import { authAssets } from '../../../constants/assets';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const SignInSection = ({
  isGoogleLinked,
  isAppleLinked,
  isTwitterLinked,
  googleDisconnect,
  appleDisconnect,
  twitterDisconnect,
  googleEmail,
  appleEmail,
  twitterEmail,
}) => (
  <Box label="LINK ACCOUNT SIGN IN">
    <div className='flex flex-1 flex-row flex-wrap justify-between gap-4 md:justify-between'>
      <SignInBox
        label="Google"
        isAccountLinked={isGoogleLinked}
        disconnectHandler={googleDisconnect}
        logoUrl={authAssets.google}
        linkUrl={`${baseUrl}/${urls.googleLogin}`}
        email={googleEmail}
      />
      <SignInBox
        label="Apple"
        isAccountLinked={isAppleLinked}
        disconnectHandler={appleDisconnect}
        logoUrl={authAssets.apple}
        linkUrl={`${baseUrl}/${urls.appleLogin}`}
        email={appleEmail}
      />
      <SignInBox
        label="Twitter"
        isAccountLinked={isTwitterLinked}
        disconnectHandler={twitterDisconnect}
        logoUrl={authAssets.x}
        linkUrl={`${baseUrl}/${urls.twitterLogin}`}
        email={twitterEmail}
      />
    </div>
  </Box>
);
