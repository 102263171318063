import { Link } from 'react-router-dom';
import { NFTMultiplierBadge } from './NFTMultiplierBadge';

export const NFTMultipliersSection = ({ multipliers, maxMultiplier }) => (
  <section className="mt-2 text-sm xxs:text-base">
    <h2 className='text-lg font-black xxs:text-2xl sm:text-2xl 2xl:text-3xl'>NFT MULTIPLIER</h2>
    <ul className='my-5 flex w-full flex-col gap-4 rounded bg-box_dark_blue px-1 py-4 sm:px-5 2xl:w-[95%]'>
      {multipliers?.length > 0
        && (
          multipliers?.map((value) => (
            <li key={value.description} className='flex'>
              <NFTMultiplierBadge multiplier={value.multiplier} cn={'basis-5/12 sm:basis-4/12 text-xs sm:text-base xl:basis-5/12'} />
              <span className='ml-2 self-center text-xs font-semibold xxs:text-sm sm:basis-6/12 sm:text-base'>{value.description}</span>
            </li>
          ))
        )
      }
    </ul>
    <div className='mb-4 px-2 font-normal lg:w-full xl:w-4/5'>
      {`Gain NFT Multipliers up to ${maxMultiplier}x if you connect your wallet and hold NFTs.`}
    </div>
    <Link
      to={'https://fableborne.medium.com/boost-your-bronze-essence-with-nft-multipliers-34c79324dc77'}
      className='px-2 underline'
    >
      Click here to learn more
    </Link>
    <Link
      to={'/dashboard'}
      className='text-shadow-custom custom-color__brightness relative my-4 ml-0 flex h-[42px] w-48 items-center justify-center bg-blueBtnLarge bg-contain bg-no-repeat md:h-[49px] md:w-56'
    >
      <p className='absolute text-base tracking-wide drop-shadow-lg sm:text-xl'>
        MANAGE WALLETS
      </p>
    </Link>
  </section>
);
