import { useState } from 'react';
import { icons } from '../../../constants/assets';
import { QuestListItem } from './list-item';

export const ClaimedQuestsAccordion = ({ quests, label }) => {
  const [isOpened, setIsOpened] = useState(false);

  if (!quests.length) {
    return null;
  }

  const handleToggle = () => {
    setIsOpened(!isOpened);
  };

  return (
    <>
      <div
        className='flex cursor-pointer items-center gap-3 px-4 py-2 font-medium opacity-80'
        onClick={handleToggle}
      >
        <img
          src={icons.collapse}
          alt="arrow-icon"
          className={`size-4 transition-all ${isOpened ? 'rotate-90' : ''}`}
        />
        Completed {label} quests
      </div>
      <ul className={`my-4 flex w-full flex-col gap-4 ${isOpened ? 'animate-expand' : 'animate-collapse'}`}>
        {quests?.map((quest) => (
          <QuestListItem
            quest={quest}
            key={quest.id}
          />
        ))}
      </ul>
    </>
  );
};
