import { useState, useEffect } from 'react';
import { ConfirmPopUp } from '../components/general/Confirm';

export const useConfirmPopUp = ({ confirmOnly } = { confirmOnly: false }) => {
  const [promise, setPromise] = useState(null);
  const [message, setErrorMessage] = useState('');
  const [title, setTitle] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const confirm = ({ message: msg, title: t }) => new Promise((resolve) => {
    setShowPopup(true);
    setErrorMessage(msg);
    setTitle(t);
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
    setShowPopup(false);
    setErrorMessage('');
    setTitle('');
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  useEffect(() => {
    document.body.style.overflow = showPopup ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [showPopup]);

  const ConfirmationDialog = () => {
    if (!showPopup) return null;

    return (
      <ConfirmPopUp
        title={title}
        message={message}
        confirmOnly={confirmOnly}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        handleClose={handleClose}
      />
    );
  };

  return [ConfirmationDialog, confirm];
};
