import { twMerge } from 'tailwind-merge';
import { useSelector } from 'react-redux';
import { playToAirdropAssets } from '../../../constants/assets';
import { AppStoreBtn, GooglePlayBtn } from '../buttons';
import { formatDate, getTournamentState } from '../../../utils';
import { TournamentStateEnum } from '../../../enums';
import { seasonSelector } from '../../../redux/slices/season.slice';

const tournamentStateMapper = {
  [TournamentStateEnum.ACTIVE]: 'LIVE',
  [TournamentStateEnum.INACTIVE]: 'ENDED',
  [TournamentStateEnum.COMING]: 'COMING',
};

const formatTournamentDateRange = (startDate, endDate) => {
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return `${formattedStartDate} to ${formattedEndDate}`;
};

export const PlayToAirdrop = () => {
  const season = useSelector(seasonSelector);

  const pagePath = window.location.pathname;
  const isRaidPageOpen = pagePath === '/referrals';

  const {
    appleStoreUrl, googlePlayUrl,
    startDate, endDate,
  } = season?.playToAirdrop || {};

  const tournamentDateRange = formatTournamentDateRange(startDate, endDate);

  const tournamentState = getTournamentState(season.playToAirdrop);

  return (
    <div className={twMerge(
      'relative w-full h-[240px] bg-newNavDarkBlue xxs:min-w-[350px] md:h-[270px] xl:h-[300px] 2xl:h-[330px]',
      isRaidPageOpen && 'flex items-center h-[260px] md:h-[290px] xl:h-[320px] 2xl:h-[350px]',
    )}>
      <div className={twMerge(
        'absolute flex h-[220px] w-full justify-center bg-mobileBanner bg-cover bg-center bg-no-repeat md:h-[250px] xl:h-[280px] 2xl:h-[310px] lg:bg-longBannerHeroes',
        !isRaidPageOpen && 'top-0',
      )}
      >
        <div className={twMerge(
          'absolute bottom-[10px] font-poppins font-black md:bottom-[15px] lg:right-14 lg:top-3 xl:top-12 2xl:top-16',
          isRaidPageOpen && 'lg:bottom-12 lg:right-[4.5rem]',
        )}>
          <div className='relative flex w-screen min-w-[300px] flex-col items-center text-center sm:w-[30rem]'>
            <img
              width='500'
              height='250'
              src={playToAirdropAssets.logo}
              className='mb-12 w-[250px] md:w-[300px] lg:w-[350px]'
            />
            <h2 className='text-shadow-custom__deeper absolute bottom-[50px] w-full text-center text-2xl font-black tracking-normal text-white md:text-3xl'>
              TOURNAMENT {tournamentStateMapper[tournamentState]}
            </h2>
            {tournamentState !== TournamentStateEnum.COMING && (
              <p className='absolute bottom-[20px] text-xl tracking-normal text-black'>
                {tournamentDateRange}
              </p>
            )}
            {TournamentStateEnum.ACTIVE === tournamentState && (
              <div className='absolute top-[160px] flex w-full justify-center gap-2 xxs:min-w-[355px] md:top-[185px] md:gap-4 lg:top-[220px]'>
                <GooglePlayBtn url={googlePlayUrl} />
                <AppStoreBtn url={appleStoreUrl} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
