import { Link } from 'react-router-dom';
import { useAuthRequired } from '../../hooks/useAuthRequired';

function PageNotFound() {
  const fullPath = window.location.pathname + window.location.search;

  useAuthRequired({ strict: false });

  return (
    <main className="place-items-cente grid h-screen min-h-full bg-blueLinearGradient px-6 py-24 font-skolar sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          {fullPath === '/errors?type=login_link'
            ? 'Link Expired'
            : 'Page not found'}
        </h1>
        <p className="mt-6 text-base leading-7 text-white md:text-lg">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to={'/dashboard'}
            href="/dashboard"
            className="rounded-md bg-grape px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>
  );
}

export default PageNotFound;
