import { twMerge } from 'tailwind-merge';

export const OAuthStrategies = ({
  url, icon, name, className, action = 'Sign in',
}) => (
  <a
    href={url}
    className={twMerge('flex items-center w-full p-2 h-[80px] rounded-sm', className)}
  >
    <div className='size-[64px] rounded-sm bg-white p-2.5'>
      <img
        className='size-full'
        src={icon}
        alt={`${name.toLowerCase()}-icon`}
      />
    </div>

    <p className="ml-6 text-2xl text-white">
      {action} with {name}
    </p>
  </a>
);
