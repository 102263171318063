import '../../styles/signUp.scss';
import { ReactBasicScroll } from 'react-basic-scroll';
import { useEffect } from 'react';
import { generalAssets, homePageAssets } from '../../constants/assets';
import { GooglePlayBtn, AppStoreBtn } from '../quests/buttons';

function SignUp() {
  const googlePlayUrl = 'https://bit.ly/3WRojmb';
  const appleStoreUrl = 'https://apple.co/46WpXHN';

  useEffect(() => {
    setTimeout(() => {
      const fableborneLogo = document.getElementById('fableborne-logo');
      fableborneLogo.classList.add('fade-in-2', '!opacity-100');
    }, 300);
    // setTimeout(() => {
    //   const signUpBtn = document.getElementById('signUpBtn');
    //   signUpBtn.classList.add('fade-in-2', '!opacity-100');
    // }, 900);
  }, []);

  const bg = {
    from: 0,
    to: 1500,
    props: {
      '--bgY': {
        from: '0px',
        to: '100px',
      },
    },
  };

  const config = {
    from: 0,
    to: 1500,
    props: {
      '--translateY': {
        from: '0',
        to: '50px',
      },
      '--scale': {
        from: '1',
        to: '1.5',
      },
      '--opacity': {
        from: 0,
        to: 1,
      },
    },
  };

  const heroesSet = {
    from: 0,
    to: 1200,
    props: {
      '--trY': {
        from: '-1%',
        to: '-10%',
      },
      '--sc': {
        from: '1',
        to: '1.4',
      },
    },
  };

  return (
    <form
      action="https://pixiongames.us14.list-manage.com/subscribe/post?u=08503f3cbdb5857d67a4a390c&amp;id=918224b03a"
      method="post"
      id="embedded-subscribe-form"
      name="embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <div className="sign-up relative top-0 flex" id="signup">
        <ReactBasicScroll config={bg}>
          <img
            src={homePageAssets.signUpBg}
            alt="fakebg"
            className="sign-up-fake-bg sign-scene relative inset-x-0 top-0 z-0 m-auto h-auto w-full max-w-none"
            style={{
              transform: 'translateY(var(--bgY))',
              willChange: 'transform',
            }}
            width="1920"
            height="1080"
          />
        </ReactBasicScroll>
        <ReactBasicScroll config={heroesSet}>
          <img
            src={homePageAssets.clouds}
            alt="Heroes"
            className="heroes sign-scene absolute top-[24%] z-10 w-full"
            style={{
              transform: 'translateY(var(--trY)) scale(var(--sc))',
              willChange: 'transform',
            }}
          />
        </ReactBasicScroll>

        <img
          src={homePageAssets.signUpForeground}
          alt="slashForeground"
          className="signup-foreground absolute h-[500px] w-auto max-w-none"
          width="1920"
          height="1080"
        />

        <div className="absolute inset-x-0 top-[14%] z-20 m-auto flex w-[70%] flex-col items-center justify-center text-center">
          <ReactBasicScroll config={config}>
            <img
              src={generalAssets.mainLogo}
              alt="fableborne-logo"
              className="logo relative z-[300] h-auto w-4/5 opacity-0"
              data-modifier="10"
              id="fableborne-logo"
              style={{
                transform: 'translateY(var(--translateY))',
                willChange: 'transform',
              }}
              width="1258"
              height="205"
            />
          </ReactBasicScroll>
          <div className='mt-5 flex w-[460px] justify-center gap-2'>
            <GooglePlayBtn url={googlePlayUrl} />
            <AppStoreBtn url={appleStoreUrl} />
          </div>
          {/* <a
            className="pre-register__signup__button mt-[20px] w-full max-w-[200px] opacity-0 transition-all duration-300 ease-in hover:scale-110"
            id="signUpBtn"
            href="/sign-up"
          >
            <img
              id="cta_signup_hero_btn"
              className='h-auto w-full'
              src={buttons.signUp}
              alt="signupButton"
              width="760"
              height="355"
            />
          </a> */}
        </div>
      </div>
    </form>
  );
}

export default SignUp;
