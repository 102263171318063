import axios from 'axios';
import { urls } from '../constants/urls';

const debugEmail = process.env.REACT_APP_DEBUG_EMAIL;
const env = process.env.REACT_APP_NODE_ENV;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  ...(env === 'local' && {
    withCredentials: true,
  }),
});

if (env === 'local') {
  instance.interceptors.response.use(
    (response) => response,
    async (err) => {
      const error = err.response;
      console.log('🚀 ~ error:', error);

      if (error?.status === 401 && error?.config && !error?.config?.isRetryRequest) {
        error.config.isRetryRequest = true;
        await instance.post(`/${urls.debug}/${debugEmail}`);
      }

      return error;
    },
  );
}
