import React, { useEffect } from 'react';
import { Blocks } from 'react-loader-spinner';
import { generalAssets } from '../../constants/assets';
import { handleRedirect } from '../../context/dashboard/handleRedirect';

function VerifyEmailMessage() {
  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <div className="h-screen w-full bg-blueLinearGradient">
      <div className="flex h-screen flex-col items-center justify-center">
        <div>
          <div className="mb-16 w-full ">
            <img
              className="mx-auto w-[70%] max-w-[500px] md:w-full"
              src={generalAssets.mainLogo}
              alt="logo"
            />
          </div>
        </div>
        <div className="flex w-4/5 flex-col items-center">
          <h3 className="font-title mb-8 text-center font-skolar text-base font-black tracking-[3px] text-darker_font_blue drop-shadow-[0_4px_4px_rgba(0,0,0,.15)] sm:text-lg md:text-xl lg:pt-4 lg:text-2xl xl:text-3xl">
            PLEASE CHECK YOUR EMAIL TO AUTHORIZE LOGIN
          </h3>
          <div className="flex items-center justify-center pt-4">
            <Blocks
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="blocks-loading"
              wrapperClass="blocks-wrapper"
              visible
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailMessage;
