import React, { forwardRef } from 'react';
import hasWebpVersion from '../../helpers/webp/hasWebpVersion';
import outputImageWebpSrcset from '../../helpers/webp/outputImageWebpSrcset';
import outputImageWidth from '../../helpers/webp/outputImageWidth';
import outputImageHeight from '../../helpers/webp/outputImageHeight';

const Image = forwardRef(
  (
    {
      alt,
      src,
      style,
      className,
      id,
      dataModifier,
      output = ['small', 'medium', 'large', 'xlarge', 'massive'],
    },
    ref,
  ) => (
      <>
        {src && (
          <picture>
            {hasWebpVersion(src, 'small') && (
              <source
                ref={ref}
                srcSet={outputImageWebpSrcset(src, output)}
                type="image/webp"
                sizes="100vw"
                style={style}
                data-modifier={dataModifier}
                loading="lazy"
              />
            )}

            <img
              ref={ref}
              alt={alt || ''}
              src={src}
              width={outputImageWidth(src) !== 0 ? outputImageWidth(src) : ''}
              height={outputImageHeight(src) !== 0 ? outputImageWidth(src) : ''}
              className={className}
              sizes="100vw"
              style={style}
              data-modifier={dataModifier}
              loading="lazy"
              id={id}
            />
          </picture>
        )}
      </>
  ),
);

export default Image;
