import { storesAssets } from '../../../constants/assets';

const GooglePlayLogo = storesAssets.googlePlay;

export const GooglePlayBtn = ({ url }) => (
  <a
    className="flex w-[150px] items-center gap-2 rounded-lg border-2 border-gray-400 bg-black p-1 xxs:w-[175px] xxs:p-2 md:basis-2/3"
    href={url}
  >
    <img src={GooglePlayLogo} alt='Google Play' className='w-8 md:w-12' />
    <div className='text-left text-white'>
      <p className='text-xxs font-light xxs:text-xs md:text-sm'>GET IT ON</p>
      <h3 className='xxs:text-md text-xs font-medium md:text-xl'>Google Play</h3>
    </div>
  </a >
);
