import '../../styles/footer.scss';
import { useContext } from 'react';
import CookieContext from '../../context/cookie/CookieContext';

function Footer({ data }) {
  const { dispatch2 } = useContext(CookieContext);
  const getCookie = (e) => {
    e.preventDefault();
    dispatch2({ type: 'SET_VISIBLE' });
  };

  return (
    <div className="footer">
      <div className="footer-body">
        <div className="footer-links">
          {data?.attributes?.subLinks.map((anchor) => (
            <a href={anchor.url} className="link-item" key={anchor.id}>
              {anchor.label}
            </a>
          ))}
          <a href="#" className="link-item" onClick={getCookie}>
            Cookie
          </a>
        </div>

      </div>
    </div>
  );
}

export default Footer;
