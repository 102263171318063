import { useQuery } from '@tanstack/react-query';
import { instance } from '../../instance';
import { urls } from '../../../constants/urls';
import { seasonInitialState } from '../../../redux/slices/season.slice';

const getMultipliers = async (seasonId) => {
  const { data: multipliers } = await instance.get(`/${urls.multipliers(seasonId)}`);

  return multipliers;
};

export const useMultipliers = (seasonId) => useQuery({
  queryKey: [urls.multipliers(seasonId)],
  queryFn: () => getMultipliers(seasonId),
  retry: false,
  enabled: seasonId !== seasonInitialState.id,
});
