import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
};

export const navBarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    logIn: (state) => {
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoggedIn, logIn, logOut } = navBarSlice.actions;
export const navBarSelector = (state) => state.navBar;

export default navBarSlice.reducer;
