import { instance } from '../../instance';
import { urls } from '../../../constants/urls';
import { errorMessageMap } from '../../../utils';

export const getMessageForWalletLogin = (wallet) => instance.get(urls.walletGetMessage(wallet));

export const getMessageForDashboard = async ({
  wallet,
  confirm,
}) => {
  try {
    return instance({
      url: urls.walletDashboardGetMessage(wallet),
      method: 'GET',
    });
  } catch (e) {
    await confirm({
      message: errorMessageMap(e),
      title: 'WALLET LINK',
    });

    throw new Error(e);
  }
};

export const walletSignIn = (wallet, data) => instance({
  method: 'POST',
  url: `/${urls.walletSignInMessage(wallet)}`,
  data,
});

export const linkWallet = async (data) => instance({
  method: 'POST',
  url: `/${urls.linkWallet}`,
  data,
});

export const dashboardWalletLink = async ({
  wallet,
  data,
}) => instance({
  method: 'POST',
  url: `/${urls.walletDashboardLink(wallet)}`,
  data,
});

export const unlinkWallet = async (fetchData) => {
  await instance({
    method: 'POST',
    url: `/${urls.unlinkWallet}`,
  });
  fetchData();
};

export const unlinkDashboardWallet = async ({
  wallet,
  fetchData,
  confirm,
}) => {
  try {
    await instance({
      method: 'POST',
      url: `/${urls.walletDashboardUnlink(wallet)}`,
    });
  } catch (e) {
    await confirm({
      message: errorMessageMap(e),
      title: 'WALLET UNLINK',
    });
  }
  fetchData();
};

export const setDashboardMainWallet = async ({
  wallet,
  confirm,
}) => {
  try {
    await instance({
      method: 'POST',
      url: `/${urls.walletSetMain(wallet)}`,
    });
  } catch (e) {
    await confirm({
      message: errorMessageMap(e),
      title: 'SETTING MAIN WALLETS',
    });
  }
};
