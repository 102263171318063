import { twMerge } from 'tailwind-merge';
import { Badge } from '../../general/Badge';

export const EligibleBadge = ({ isEligible }) => (
  <>
    <Badge
      cn={
        twMerge(
          'absolute sm:-top-3 -left-4 z-20 bg-black text-[#FFDC30] border-b-[3px] py-0 font-black',
          isEligible && 'bg-[#50D500] text-black',
        )
        }>
      {`${!isEligible ? 'NOT' : ''} ELIGIBLE`}
    </Badge>
    <div className='absolute -left-4 top-[1.1rem] z-0 h-2 w-36 rounded-b bg-black sm:top-4'></div>
 </>
);
