import { twMerge } from 'tailwind-merge';
import { Badge } from '../general/Badge';
import { EligibleBadge } from '../quests/general/EligibleBadge';
import { DEFAULT_TOP_REWARDED, MAX_LEADERBOARD_LENGTH } from '../../constants';
import { icons } from '../../constants/assets';

export const Leaderboard = ({
  data, isQuestPage, isLoading, isEligible,
}) => {
  const essenceType = `${isQuestPage ? 'Bronze' : 'Silver'}`;
  const {
    leaderboard,
    leaderboardTopRewarded = DEFAULT_TOP_REWARDED,
  } = data ?? {};
  const { entries = [], userEntry = {} } = leaderboard ?? {};
  const isInTop = userEntry?.position <= MAX_LEADERBOARD_LENGTH;
  const isNoRegistration = entries?.length === 0;

  return (
    <section className='font-poppins'>
      <header className='flex items-center sm:gap-2'>
        <img
          src={icons.crown}
          alt="crown-icon"
          className='size-[30px] xxs:size-[36px] sm:size-[42px]'
        />
        <h2
          className='text-base font-black xxs:text-xl sm:text-2xl'
        >
          {`${essenceType.toUpperCase()} LEADERBOARD`}
        </h2>
      </header>
      <table className='w-full rounded'>
        <thead>
          <tr className='text-left align-middle text-lg text-blue-300 sm:text-xl'>
            <th className='w-1 whitespace-nowrap'>Rank</th>
            <th className='pl-4 text-left'>Username</th>
            <th className='text-right'>{`${essenceType} Essence`}</th>
          </tr>
        </thead>

        <tbody className='rounded bg-[#09214750] font-medium'>
          <tr className='h-2' />
          {!isLoading && !isNoRegistration
            ? <>
              {entries.map((user) => {
                const isCurrentUser = isInTop && userEntry?.position === user?.position;

                return (
                  <tr key={user.userId} className='text-sm font-medium sm:text-xl'>
                    <td
                      className={
                        twMerge(
                          'text-center py-2 whitespace-nowrap w-1 first:rounded-tl last:rounded-bl',
                          isCurrentUser && 'bg-lightBlue rounded-tl rounded-bl',
                        )
                      }
                    >
                      {user.position}
                    </td>
                    <td
                      className={
                        twMerge(
                          'whitespace-nowrap w-1 pl-4 underline',
                          isCurrentUser && 'bg-lightBlue',
                        )
                      }
                    >
                      {user?.username?.normalized}
                    </td>
                    <td
                      className={
                        twMerge(
                          'text-right pr-4 first:rounded-tr last:rounded-br',
                          isCurrentUser && 'bg-lightBlue rounded-tr rounded-br',
                        )
                      }
                    >
                      {user.score.toLocaleString()}
                    </td>
                  </tr>
                );
              })}
              <tr className='h-6'>
                <td
                  colSpan={3}
                  className='rounded-b bg-[#09214701]'
                />
              </tr>
            </>
            : (
              <tr>
                <td
                  colSpan={3}
                  className='h-56 rounded text-center align-middle text-base font-medium opacity-80'
                >
                  No registration yet
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
      <div className='relative h-20'>
        {!isNoRegistration && isQuestPage && <EligibleBadge isEligible={isEligible} />}
        <Badge cn={'absolute bottom-5 w-full z-10 bg-[#045E9D] flex h-12 items-center border-black border-[1px] border-b-4 font-medium sm:text-xl mt-4'}>
          <div className='ml-2'>
            {isNoRegistration ? '-' : userEntry?.position}
          </div>
          <div className='ml-10 underline'>
            {userEntry?.username?.normalized}
          </div>
          <div className='ml-auto'>
            {isNoRegistration ? '-' : userEntry?.score.toLocaleString()}
          </div>
        </Badge>
      </div>
      {isQuestPage && (
        <p className='text-lg font-medium'>
          {`Only the Top ${leaderboardTopRewarded.toLocaleString()} Bronze Leaderboard rank users
          will be eligible for the Grand Prize.`}
        </p>
      )}
    </section>
  );
};
