import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validate } from '../context/signIn-signUp/regexFormValidation';
import { signIn } from '../services/auth/sign-in';
import { buttons, generalAssets } from '../constants/assets';
import { handleRedirect } from '../context/dashboard/handleRedirect';

function Authentication() {
  const [formValues, setFormValues] = useState({ email: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [notCreatedEmail, setNotCreatedEmail] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormValues({ ...formValues, email: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 300);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      (async () => {
        try {
          await signIn({
            email: formValues.email.toLowerCase(),
          });

          setFormValues({ email: '' });
          navigate('/auth/confirm');
        } catch (e) {
          console.error(e);

          if (e.response.status === 404) {
            setNotCreatedEmail(true);
          }
        }
      })();
    }

    handleRedirect();
  }, [formErrors]);

  return (
    <div className="radial-gradient md: h-full w-screen">
      <div
        className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`}
      >
        <div className="flex size-full min-h-screen items-center justify-center py-[60px] text-center md:flex md:items-center md:justify-center">
          <div className="md:w-full">
            <div className="mx-8 max-w-[500px] md:max-w-full">
              <div>
                <div className="mb-8 w-full">
                  <img
                    className="mx-auto w-[70%] max-w-[400px]"
                    src={generalAssets.mainLogo}
                    alt="logo"
                  />
                </div>
                <div className="mx-auto mt-8 w-4/5 px-2 md:max-w-[500px] lg:max-w-[700px]">
                  <h3 className="mb-8 font-skolar text-base font-black tracking-[3px] text-darker_font_blue drop-shadow-[0_4px_4px_rgba(0,0,0,.15)] sm:text-lg md:text-xl lg:pt-4 lg:text-2xl xl:text-3xl">
                    SIGN INTO YOUR ACCOUNT
                  </h3>
                </div>
              </div>
              <div className="mx-auto mt-8 w-4/5 max-w-[300px] px-2 md:max-w-[400px] lg:max-w-[450px] xl:max-w-[500px]">
                <form
                  onSubmit={handleSubmit}
                  id="cta_legacy_login_form"
                  className="flex flex-col md:pt-4"
                >
                  <div className="mb-6 flex flex-col font-objektiv">
                    <input
                      className="h-[35px] rounded-md border-0 p-[10px] text-center text-base text-black focus:outline-[#fcc96b] md:h-[40px] lg:text-lg xl:h-[50px]"
                      id="email"
                      placeholder="Enter your email"
                      type="text"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    <div className="h-6">
                      {notCreatedEmail ? (
                        <p className="mt-2 text-error_text">
                          Email does not exist
                        </p>
                      ) : (
                        <p className="mt-2 text-error_text">
                          {formErrors.email}
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="relative flex w-full items-center justify-center md:transition-all md:duration-300 md:ease-in md:hover:scale-110"
                  >
                    <img
                      className="mx-auto h-auto w-[130px] md:w-[170px]"
                      src={buttons.signIn}
                      alt="signInButton"
                    />
                  </button>
                  <div className="mt-4 flex flex-col items-center justify-center font-objektiv md:mt-8 md:flex-row">
                    <span className="text-new_blue text-xs md:text-sm">
                      Don't have Pixion Games account?
                    </span>
                    <a
                      id="cta_signup_redirect"
                      href="/sign-up"
                      className="text-new_blue mt-1 text-xs underline md:ml-1 md:mt-0 md:text-sm"
                    >
                      Sign Up
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
