const DEFAULT_API_VERSION = 'v1';

export const urls = {
  dashboard: `${DEFAULT_API_VERSION}/dashboard`,
  airdrops: `${DEFAULT_API_VERSION}/airdrops`,
  goldTracker: `${DEFAULT_API_VERSION}/pfp/essence/gold/tracker`,
  setname: `${DEFAULT_API_VERSION}/setname`,

  unlinkWallet: `${DEFAULT_API_VERSION}/unlinkwallet`,
  linkWallet: `${DEFAULT_API_VERSION}/linkwallet`,

  signin: `${DEFAULT_API_VERSION}/signin`,
  signup: `${DEFAULT_API_VERSION}/signup`,
  logout: `${DEFAULT_API_VERSION}/logout`,
  debug: `${DEFAULT_API_VERSION}/debug/login`,

  walletGetMessage: (wallet) => `${DEFAULT_API_VERSION}/walletlogin/${wallet}/message`,
  walletSignInMessage: (wallet) => `${DEFAULT_API_VERSION}/walletlogin/${wallet}/signin`,

  season: `${DEFAULT_API_VERSION}/pfp/seasons/current`,
  quests: (seasonId) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/quests`,
  claimOneTimeQuest: ({ seasonId, questId }) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/quests/${questId}/claim`,
  completeOneTimeQuest: ({ seasonId, questId }) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/quests/${questId}/complete`,
  claimDailyQuest: ({ seasonId, questId }) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/dailyquests/${questId}/claim`,
  completeDailyQuest: ({ seasonId, questId }) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/dailyquests/${questId}/complete`,

  walletDashboardGetMessage: (wallet) => `${DEFAULT_API_VERSION}/dashboard/wallet/${wallet}/message`,
  walletDashboardLink: (wallet) => `${DEFAULT_API_VERSION}/dashboard/wallet/${wallet}/link`,
  walletDashboardUnlink: (wallet) => `${DEFAULT_API_VERSION}/dashboard/wallet/${wallet}/unlink`,
  walletSetMain: (wallet) => `${DEFAULT_API_VERSION}/dashboard/wallet/${wallet}/setmain`,

  raidStatus: `${DEFAULT_API_VERSION}/rgp/raid/status`,
  raidSubmitCode: (code) => `${DEFAULT_API_VERSION}/rgp/raid/code/${code}/submit`,
  raidClaimReward: `${DEFAULT_API_VERSION}/rgp/raid/claimreward`,
  raidCheckTwitterPost: `${DEFAULT_API_VERSION}/rgp/raid/checkpost`,

  discordLogin: `${DEFAULT_API_VERSION}/discordlogin`,
  discordLogout: `${DEFAULT_API_VERSION}/discordlogout`,
  googleLogin: `${DEFAULT_API_VERSION}/googlelogin`,
  googleLogout: `${DEFAULT_API_VERSION}/googlelogout`,
  appleLogin: `${DEFAULT_API_VERSION}/applelogin`,
  appleLogout: `${DEFAULT_API_VERSION}/applelogout`,
  twitterLogin: `${DEFAULT_API_VERSION}/twitterlogin`,
  twitterLogout: `${DEFAULT_API_VERSION}/twitterlogout`,

  multipliers: (seasonId) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/multipliers`,
  leaderboard: (seasonId, type) => `${DEFAULT_API_VERSION}/pfp/seasons/${seasonId}/leaderboards/${type}`,
};
