import { dashboardAssets } from '../../constants/assets';

export const ConfirmPopUp = ({
  title, message, handleConfirm,
  handleCancel, handleClose, confirmOnly = false,
}) => (
  <div
    className="fixed inset-[-200px] z-50 flex items-center justify-center bg-walletConnectBlur"
    onClick={handleClose}
  >
    <div
      className="flex h-auto min-h-[150px] w-[300px] flex-col items-center justify-center rounded-lg border border-b-4 border-black bg-popupBackground px-8 py-4 text-center md:w-[500px]"
      onClick={(e) => e.stopPropagation()}
    >
      <h1 className="mb-4 text-sm font-medium md:text-base">
        {title}
      </h1>
      <h3 className="mb-4 text-sm font-medium md:text-base">
        {message}
      </h3>
      <div className='flex flex-col items-center gap-4 md:flex-row'>
        <button
          className="relative mr-2 flex w-[150px] items-center justify-center py-1  md:mr-3"
          onClick={handleConfirm}
        >
          <p className="absolute text-xl font-bold">
            ACCEPT
          </p>
          <img
            src={dashboardAssets.greenBtn}
            alt="green-btn"
            className="h-[50px] w-[250px]"
          />
        </button>
        {!confirmOnly && (
          <button
            className="relative mr-2 flex w-[150px] items-center justify-center py-1  md:mr-3"
            onClick={handleCancel}
          >
            <p className="absolute text-xl font-bold">
              CANCEL
            </p>
            <img
              src={dashboardAssets.redBtn}
              alt="red-btn"
              className="h-[50px] w-[250px]"
            />
          </button>
        )}
      </div>
    </div>
  </div>
);
