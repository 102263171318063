import '../../styles/social.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useEffect, useState } from 'react';
import { generalAssets } from '../../constants/assets';

function Social({ data }) {
  const [columns, setColumns] = useState('');
  const [hg, setHg] = useState('');
  const rows = [];
  for (let i = 0; i < 15; i += 1) {
    rows.push(<div className="firefly" key={i} />);
  }
  let column = '1fr';
  data?.data?.forEach((s, k) => {
    if (k > 0) {
      column += ' 1fr';
    }
  });

  const detectSize = () => {
    if (window.innerWidth > 600) {
      setColumns(column);
    } else {
      setColumns('');
      if (data?.data?.length > 4) {
        setHg('400px !important');
        if (data?.data?.length > 6) {
          setHg('500px !important');
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (window.innerWidth > 600) {
      setColumns(column);
    } else {
      setColumns('');
      if (data?.data?.length > 4) {
        setHg('400px');
        if (data?.data?.length > 6) {
          setHg('500px');
        }
      }
    }
  }, [detectSize]);
  return (
    <div
      className="socialFable"
      id="social"
      style={{
        background: `url(${generalAssets.backgrounds.castleBg})`,
      }}
    >
      {rows.map((item) => item)}
      <div className="fable-community" style={{ height: hg }}>
        <h1 className="title">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            Join the Community
          </AnimationOnScroll>
        </h1>
        <div
          className="fable-soc-icons"
          style={{ gridTemplateColumns: columns }}
        >
          {data?.data?.map((social, key) => (
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOut="animate__fadeOut"
              delay={(key + 1) * 100}
              key={key}
              animateOnce={true}
            >
              <div className="icon-item">
                <a
                  href={social.attributes.link}
                  className={social.attributes.name}
                >
                  <img src={social.attributes.logo.data?.attributes?.url} alt="icon" />
                </a>
              </div>
            </AnimationOnScroll>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Social;
