export const validateUsername = (value) => {
  const errors = {};
  const regex = /^[a-zA-Z0-9_-]+$/;

  if (value.username.length < 3) {
    errors.username = 'Username too short';
  } else if (value.username.length > 12) {
    errors.username = 'Username too long';
  } else if (!regex.test(value.username)) {
    errors.username = 'This is not a valid username';
  }

  return errors;
};
