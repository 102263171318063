import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pixionLogout } from '../../../services/integrations/pixion';
import { logOut } from '../../../redux/slices/navbar.slice';
import { dashboardAssets } from '../../../constants/assets';

export const LogOutSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dashboardDisconnect = async () => {
    try {
      await pixionLogout();

      dispatch(logOut());
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="relative my-8 flex flex-col items-center justify-center font-poppins">
      <button
        className="relative flex h-[40px] w-[150px] items-center justify-center"
        onClick={dashboardDisconnect}
      >
        <p className='text-shadow-custom absolute'>LOG OUT</p>
        <img
          src={dashboardAssets.redBtn}
          alt="log_out_btn"
        />
      </button>
      <div className="mt-8 w-full text-center text-error_text underline">
        <a href="https://pixiongames.helpshift.com/hc/en/3-fableborne/faq/74-personal-data-collection">
          Delete your account
        </a>
      </div>
    </div>
  );
};
