import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import {
  ONE_DAY_IN_MS, ONE_HOUR_IN_MS, ONE_MINUTE_IN_MS,
  ONE_SECOND_IN_MS,
} from '../constants/time';

const calculateTimeLeft = (endDate) => {
  const diff = DateTime.fromISO(endDate) - DateTime.now();

  if (diff <= 0) return {};

  const d = Math.floor(diff / (ONE_DAY_IN_MS));
  const h = Math.floor((diff / ONE_HOUR_IN_MS) % 24);
  const m = Math.floor((diff / ONE_MINUTE_IN_MS) % 60);
  const s = Math.floor((diff / ONE_SECOND_IN_MS) % 60);

  const mDisplay = d === 0;
  const sDisplay = mDisplay && h === 0;

  return {
    d,
    h,
    ...(mDisplay && { m }),
    ...(sDisplay && { s }),
  };
};

export const useCountdown = (endDate) => {
  const date = DateTime.fromISO(endDate);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

  useEffect(() => {
    const diff = DateTime.fromISO(endDate) - DateTime.now();
    let timer = null;

    if (diff > 0) {
      timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(date));
      }, ONE_SECOND_IN_MS);
    }

    return () => clearTimeout(timer);
  });

  return { timeLeft };
};
