export const Box = ({ children, label }) => (
  <div className="flex h-auto w-full flex-col rounded-lg border border-b-4 border-black bg-dashboard_box_blue p-4">
    <div className="mb-2 font-skolar text-base font-black tracking-wider md:text-lg lg:text-xl">
      {label}
    </div>
    <div>
      {children}
    </div>
  </div>
);
