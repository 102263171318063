import '../../styles/nft.scss';
import htmlParse from 'html-react-parser';
import { parse } from 'marked';
import { useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Image from '../general/Image';
import { buttons, homePageAssets } from '../../constants/assets';

function Nft({ data }) {
  const [mobileActive, setMobileActive] = useState(0);

  const onClick = (e) => {
    e.preventDefault();
    const id = e.currentTarget.id.replaceAll('ntab', '');
    e.currentTarget.classList.add('active');
    document.getElementById(`ntext${id}`).classList.add('active');
    const tabs = document.querySelectorAll('.ntabs');
    const texts = document.querySelectorAll('.ntexts');
    tabs.forEach((tab) => {
      const tabId = `ntab${id}`;
      if (tab.id !== tabId) {
        tab.classList.remove('active');
      }
    });
    texts.forEach((text) => {
      const textId = `ntext${id}`;
      if (text.id !== textId) {
        text.classList.remove('active');
      }
    });
  };

  const onMobileClickLeft = (e) => {
    e.preventDefault();
    if (mobileActive === 0) {
      return setMobileActive(0);
    }
    return setMobileActive((prevState) => prevState - 1);
  };
  const onMobileClickRight = (e) => {
    e.preventDefault();
    if (data.tabs.length - 1 > mobileActive) {
      return setMobileActive((prevState) => prevState + 1);
    }
    return setMobileActive(mobileActive);
  };

  return (
    <div
      className="nft"
      style={{
        background: `url(${homePageAssets.nftBg}) top center no-repeat`,
      }}
    >
      <AnimationOnScroll
        animateIn="animate__fadeInRight"
        animateOnce={true}
        className="reina"
      >
        <Image src={homePageAssets.glint} alt="reina" />
      </AnimationOnScroll>
      <div className="nft-body">
        <h1 className="nft-title">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            CONQUER KINGDOMS
          </AnimationOnScroll>
        </h1>

        <div className="nft-content">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            <div className="nft-tabs">
              {data.tabs.map((n, key) => {
                const { id, header } = n;
                const active = key === 0 ? ' active' : '';
                return (
                  <div
                    className={`ntabs${active}`}
                    id={`ntab${id}`}
                    key={`tabs${key}`}
                    onClick={onClick}
                  >
                    <h2 className="title">
                      {header && htmlParse(parse(header))}
                    </h2>
                  </div>
                );
              })}
            </div>

            <div className="nft-mobile-tabs">
              {mobileActive !== 0 && (
                <a href="#" className="leftSlider">
                  <img src={buttons.btnLeft} alt="left" onClick={onMobileClickLeft} />
                </a>
              )}
              {mobileActive < data.tabs.length - 1 && (
                <a href="#" className="rightSlider">
                  <img src={buttons.btnRight} alt="left" onClick={onMobileClickRight} />
                </a>
              )}
              {data.tabs.map((n, key) => {
                const { id, header } = n;
                const active = key === mobileActive ? ' active' : '';
                return (
                  <div
                    className={`ntabs${active}`}
                    id={`ntab${id}`}
                    key={`tabs${key}`}
                    onClick={onClick}
                  >
                    <h2
                      className="title"
                      dangerouslySetInnerHTML={{ __html: header }}
                    />
                  </div>
                );
              })}
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            <div className="nft-texts">
              {data.tabs.map((text, key) => {
                const active = key === mobileActive ? ' active' : '';
                return (
                  <div
                    className={`ntexts${active}`}
                    key={`texts${key}`}
                    id={`ntext${text.id}`}
                  >
                    {text.tabContent && htmlParse(parse(text.tabContent))}
                  </div>
                );
              })}
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
}

export default Nft;
