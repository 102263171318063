import React from 'react';
import { airdropDateFormatter } from '../../../context/dashboard/airdropDataFormatter';
import { eligibilityAssets } from '../../../constants/assets';

function NoRewardsReceived({ data }) {
  const { date, time } = airdropDateFormatter(data.date);

  return (
    <div className="w-full rounded-lg bg-dashboard_box_blue drop-shadow-lg">
      {/* Desktop UI from 769px */}
      <div className="relative top-[-2px] hidden h-[40px] items-center justify-center font-objektiv md:flex md:h-[50px]">
        <div className="absolute left-0 w-[105%] sm:w-[103%]">
          <img
            className="h-[40px] w-full md:h-[50px]"
            src={eligibilityAssets.greyBannerTop}
            alt="bg-img"
          />
        </div>
        <p className="absolute left-2 text-xs tracking-wide sm:text-sm sm:font-bold md:left-4 md:text-base lg:text-lg">
          AIRDROP DISTRIBUTED
        </p>
        <div className="absolute right-0 flex w-[200px] items-center justify-center md:w-[230px] lg:w-[260px]">
          <img className="w-full" src={eligibilityAssets.timeBg} alt="bg-img" />
          <div className="absolute flex flex-row text-xs tracking-[2px] text-white md:text-sm lg:text-base">
            <div>{time}</div>
            <div className="mx-1 my-[3px] border-r opacity-50"></div>
            <div className="flex flex-row">
              <p className="pl-[2px]">{date}</p>
              <p className="ml-1 opacity-50">UTC</p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="mx-3 md:mx-8 md:mt-6">
        <div>
          <div className="flex flex-col py-4 text-white md:mt-0">
            <p className="w-40 font-skolar text-2xl font-bold tracking-[2px] md:w-52 md:text-3xl">
              {data.eligibilityText}
            </p>
            <p className="my-2 pr-2 font-objektiv tracking-wider md:pr-12 md:text-lg">
              {data.eligibilityCriteriaText}
            </p>
          </div>
        </div>
      </div>
      {/* Bottom distribution UI until 769px */}
      <div className="relative top-1 mt-6 flex h-[40px] items-center justify-center font-objektiv md:hidden">
        <div className="absolute left-0 w-[103%] sm:w-[103%]">
          <img
            className="h-[40px] w-full"
            src={eligibilityAssets.greyBannerBottom}
            alt="bg-img"
          />
        </div>
        <p className="absolute left-2 text-xs font-bold tracking-wide sm:text-sm">
          Airdrop distributed
        </p>
        <div className="absolute right-0 flex w-[190px] items-center justify-center">
          <img className="w-full" src={eligibilityAssets.timeBg} alt="bg-img" />
          <div className="absolute flex flex-row text-xs tracking-[1px] text-white">
            <div>{time}</div>
            <div className="mx-1 my-[3px] border-r opacity-50"></div>
            <div className="flex flex-row">
              <p className="pl-[2px]">{date}</p>
              <p className="ml-1 opacity-50">UTC</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoRewardsReceived;
