import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleDashboardRequest } from '../context/dashboard/handleDashboardRequest';
import { googleLogout } from '../services/integrations/google';
import { appleLogout } from '../services/integrations/apple';
import { discordLogout } from '../services/integrations/discord';
import { twitterLogout } from '../services/integrations/twitter';
import {
  MyProfileSection,
  SignInSection,
  AdditionalFeaturesSection,
  LogOutSection,
  LinkedWalletsSection,
  PointsSection,
} from '../components/dashboard/sections';
import { errorMessageMap } from '../utils';
import { useConfirmPopUp } from '../hooks/useConfirmPopUp';
import { logIn } from '../redux/slices/navbar.slice';
import Spinner from '../components/general/Spinner';

const env = process.env.REACT_APP_NODE_ENV;

function Dashboard() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDiscordLinked, setIsDiscordLinked] = useState(false);
  const [isGoogleLinked, setIsGoogleLinked] = useState(false);
  const [isAppleLinked, setIsAppleLinked] = useState(false);
  const [isTwitterLinked, setIsTwitterLinked] = useState(false);
  const [PopUp, confirm] = useConfirmPopUp({ confirmOnly: true });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const { status, data: fetchedData } = await handleDashboardRequest();

      if (status === 401 || !fetchedData) {
        navigate('/sign-in');
      } else if (status !== 200) {
        navigate('/errors');
      } else if (!fetchedData.username && fetchedData.legacy === false) {
        navigate('/create-username');
      } else if (fetchedData.legacy && !fetchedData.username && env !== 'local') {
        navigate('/legacy-create-username');
      } else {
        setData(fetchedData);
        setIsDiscordLinked(fetchedData.discordAccountLinked);
        setIsGoogleLinked(fetchedData.googleAccountLinked);
        setIsAppleLinked(fetchedData.appleAccountLinked);
        setIsTwitterLinked(fetchedData.twitterAccountLinked);
        setLoading(false);

        dispatch(logIn());
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isDiscordLinked, isAppleLinked, isGoogleLinked]);

  // disconnects bellow
  const googleDisconnect = async () => {
    try {
      const { googleAccountLinked } = await googleLogout();
      setIsGoogleLinked(googleAccountLinked);
    } catch (e) {
      await confirm({
        message: errorMessageMap(e),
        title: 'GOOGLE DISCONNECT',
      });
    }
  };

  const appleDisconnect = async () => {
    try {
      const { appleAccountLinked } = await appleLogout();
      setIsAppleLinked(appleAccountLinked);
    } catch (e) {
      await confirm({
        message: errorMessageMap(e),
        title: 'APPLE DISCONNECT',
      });
    }
  };

  const twitterDisconnect = async () => {
    try {
      const { twitterAccountLinked } = await twitterLogout();
      setIsTwitterLinked(twitterAccountLinked);
    } catch (e) {
      await confirm({
        message: errorMessageMap(e),
        title: 'TWITTER DISCONNECT',
      });
    }
  };

  const discordDisconnect = async () => {
    try {
      const { discordAccountLinked } = await discordLogout();
      setIsDiscordLinked(discordAccountLinked);
    } catch (e) {
      await confirm({
        message: errorMessageMap(e),
        title: 'DISCORD DISCONNECT',
      });
      console.error(e);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="relative">
      <div className="flex w-full justify-center bg-gradient-to-b from-bg_gradient_light_blue to-bg_gradient_dark_blue">
        <div className="mt-[100px] flex w-full min-w-[300px] max-w-[800px] flex-col gap-8 px-5 text-white">
          <MyProfileSection
            pixionId={data?.pixionId}
            username={data?.username?.normalized}
          />
          <SignInSection
            isGoogleLinked={isGoogleLinked}
            googleDisconnect={googleDisconnect}
            googleEmail={data?.googleUserInfo?.email}
            isAppleLinked={isAppleLinked}
            appleDisconnect={appleDisconnect}
            appleEmail={data?.appleUserInfo?.email}
            isTwitterLinked={isTwitterLinked}
            twitterDisconnect={twitterDisconnect}
            twitterEmail={data?.twitterUserInfo?.username}
          />
          <AdditionalFeaturesSection
            discordDisconnect={discordDisconnect}
            isDiscordLinked={isDiscordLinked}
            pixionId={data?.pixionId}
            username={data?.discordUserInfo?.username}
          />
          <LinkedWalletsSection
            data={data}
            fetchData={fetchData}
            confirm={confirm}
          />
          <PointsSection
            resources={data?.resources}
            walletAddress={data?.walletAddress}
          />
          <LogOutSection />
          <PopUp />
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
