import React, { useEffect, useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useSignMessage, useAccountEffect } from 'wagmi';
import { twMerge } from 'tailwind-merge';
import { Box } from '../shared/Box';
import { ActiveWalletBox } from '../shared/ActiveWalletBox';
import { RegisteredWalletBox } from '../shared/RegisteredWalletBox';
import {
  getMessageForDashboard,
  dashboardWalletLink,
  unlinkDashboardWallet,
  setDashboardMainWallet,
} from '../../../services/integrations/wallet';
import { errorMessageMap } from '../../../utils';
import { WalletConnectStateEnum } from '../../../enums';

export const LinkedWalletsSection = ({
  data,
  fetchData,
  confirm,
}) => (
  <ConnectButton.Custom>
    {({
      account, chain, openConnectModal, openAccountModal,
    }) => {
      const [hasTriedToConnect, setHasTriedToConnect] = useState(false);
      const [accountAddress, setAccountAddress] = useState('');
      const [buttonTextState, setButtonTextState] = useState('');

      const mainWalletAddress = data.walletAddress;
      const connected = !!(account && chain);

      const { signMessageAsync } = useSignMessage();

      const walletConnect = () => {
        if (!connected) {
          setTimeout(() => {
            openConnectModal();
          }, 500);
        }
        setHasTriedToConnect(true);
      };

      const walletSign = async () => {
        try {
          if (connected) {
            const { data: message } = await getMessageForDashboard({
              wallet: accountAddress,
              confirm,
            });
            const signature = await signMessageAsync({
              account: accountAddress,
              message,
            });
            await dashboardWalletLink({
              wallet: accountAddress,
              data: { signature },
              confirm,
            });
            fetchData();
          }
        } catch (e) {
          await confirm({
            message: errorMessageMap(e),
            title: 'WALLET LINK',
          });
        }
      };

      const walletSetMain = async (prop) => {
        await setDashboardMainWallet({
          wallet: prop,
          confirm,
        });
        fetchData();
      };

      const walletDisconnect = async () => {
        openAccountModal();
      };

      useAccountEffect({
        onDisconnect() {
          unlinkDashboardWallet({
            wallet: accountAddress,
            fetchData,
            confirm,
          });
        },
      });

      const checkWalletMatch = () => data.wallets.includes(accountAddress);
      const isWalletMatched = checkWalletMatch();

      useEffect(() => {
        if (connected) {
          setAccountAddress(account?.address);
        }

        if (chain?.unsupported) {
          setButtonTextState(WalletConnectStateEnum.WRONG_NETWORK);
        } else if (mainWalletAddress) {
          setButtonTextState(WalletConnectStateEnum.CONNECT_ANOTHER_WALLET);
        } else {
          setButtonTextState(WalletConnectStateEnum.CONNECT_WALLET);
        }
      }, [
        account,
        accountAddress,
        connected,
        hasTriedToConnect,
        isWalletMatched,
        chain?.unsupported,
        mainWalletAddress,
      ]);

      return (
        <Box>
          <div className="flex w-full flex-col">
            <div className="mb-2 w-[200px] text-left font-skolar text-base font-black tracking-wider md:text-lg lg:text-xl">
              LINKED WALLETS
            </div>
            <ul>
              {data.wallets.map((wallet, idx) => (
                <RegisteredWalletBox
                  key={idx}
                  mainWalletAddress={mainWalletAddress}
                  wallet={wallet}
                  walletDisconnect={walletDisconnect}
                  walletSetMain={walletSetMain}
                />
              ))}
              <ActiveWalletBox
                connected={connected}
                isWalletMatched={isWalletMatched}
                accountAddress={accountAddress}
                walletSign={walletSign}
                walletDisconnect={walletDisconnect}
              />
            </ul>
            <button
              disabled={buttonTextState === WalletConnectStateEnum.WRONG_NETWORK}
              onClick={walletConnect}
              className={twMerge(
                'flex max-w-[320px] items-center justify-center bg-contain bg-no-repeat',
                !mainWalletAddress && connected
                  ? 'bg-grayBtnLarge h-[57px]'
                  : 'bg-yellowBtnLargeNormal h-[57px]',
              )}
            >
              <p className="text-shadow-custom text-base font-black tracking-wider md:text-lg">
                {buttonTextState}
              </p>
            </button>
          </div>
        </Box>
      );
    }}
  </ConnectButton.Custom>
);
