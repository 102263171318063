import { getDashboard } from '../../services/api/dashboard';

export const handleDashboardRequest = async () => {
  try {
    const { status, data } = await getDashboard();

    return { status, data };
  } catch (e) {
    return {
      status: e.response.status,
      data: null,
    };
  }
};
