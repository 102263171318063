import { shortifyWallet } from '../../../utils';

export const ActiveWalletBox = ({
  connected,
  isWalletMatched,
  accountAddress,
  walletSign,
  walletDisconnect,
}) => {
  const handleClick = () => {
    setTimeout(() => {
      walletDisconnect();
    }, 500);
  };

  return (
    <li className="mb-2 flex flex-col font-poppins">
      {connected && !isWalletMatched && (
        <div className="flex flex-row items-center justify-between">
          <div className="flex h-auto w-full flex-wrap items-center justify-between rounded bg-inner_box_blue p-2 md:p-3">
            <p className="text-sm font-bold tracking-wider text-white sm:text-base">
              {shortifyWallet(accountAddress)}
            </p>
            <div className="flex flex-row items-center">
              <button
                onClick={() => walletSign()}
                className="relative mr-2 flex h-[25px] w-[40px] items-center justify-center bg-greenBtnSmall bg-contain bg-no-repeat sm:h-[30px] sm:w-[50px] md:mr-3 md:h-[35px] md:w-[57px]"

              >
                <p className="text-shadow-custom absolute text-xxs font-black tracking-wider md:text-xs">
                  SIGN
                </p>
              </button>
              <button
                onClick={() => handleClick()}
                className="relative ml-1 flex h-[25px] w-[85px] items-center justify-center bg-redBtn bg-contain bg-no-repeat sm:h-[30px] sm:w-[100px] md:h-[35px] md:w-[119px]"
              >
                <p className="text-shadow-custom absolute text-xxs font-black tracking-wider md:text-xs">
                  DISCONNECT
                </p>
              </button>
            </div>
          </div>
          <div className="ml-3 flex h-[35px] w-[52px] cursor-not-allowed items-center justify-center rounded border border-b-[3px] border-border_bt_shadow border-b-border_gray_100 px-2">
            <p className="text-xs font-black tracking-wider text-border_gray_100">MAIN</p>
          </div>
        </div>
      )}
    </li>
  );
};
