import { icons } from '../../../constants/assets';
import { useCountdown } from '../../../hooks/useCountDown';
import { Badge } from '../../general/Badge';

export const DailyQuestsCountdown = ({ endDate }) => {
  const { timeLeft } = useCountdown(endDate);

  const timerComponents = Object
    .entries(timeLeft)
    .filter(([, value]) => value > 0)
    .map(([interval, value]) => (
      <span key={interval}>
        {value} {interval}
      </span>
    ));

  if (!timerComponents.length) {
    return null;
  }

  return (
    <Badge cn={'flex bg-black gap-1 sm:gap-2 self-center items-center align-middle font-black font-skolar text-md sm:text-2xl'}>
      <img
        src={icons.hourglass}
        alt='hourglass-icon'
        className='size-6 sm:size-8'
      />
      {timerComponents}
    </Badge>
  );
};
