import { getDefaultConfig, connectorsForWallets } from '@rainbow-me/rainbowkit';
import { mainnet } from 'wagmi/chains';
import { http } from 'wagmi';
import { metaMaskWallet, magicEdenWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';

const connectors = connectorsForWallets(
  [{
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet,
      magicEdenWallet,
      walletConnectWallet,
    ],
  }],
  {
    appName: 'Pixion Games Connect',
    projectId: `${process.env.REACT_APP_API_PROJECTID}`,
  },
);

export const config = getDefaultConfig({
  connectors,
  appName: 'Pixion Games Connect',
  projectId: `${process.env.REACT_APP_API_PROJECTID}`,
  chains: [mainnet],
  ssr: true,
  transports: {
    [mainnet.id]: http(),
  },
});
