import { storesAssets } from '../../../constants/assets';

const AppStoreLogo = storesAssets.appStore;

export const AppStoreBtn = ({ url }) => (
  <a
    className="flex w-[150px] items-center gap-2 rounded-lg border-2 border-gray-400 bg-black p-2 xxs:w-[175px] md:basis-2/3"
    href={url}
  >
    <img src={AppStoreLogo} alt='App Store' className='size-8 md:size-10' />
    <div className='text-left text-white'>
      <p className='text-xxs font-light xxs:text-xs md:text-sm'>DOWNLOAD ON THE</p>
      <h3 className='xxs:text-md text-xs font-medium md:text-xl'>App Store</h3>
    </div>
  </a>
);
