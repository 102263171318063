import { ONE_MINUTE_IN_MS } from '../constants/time';

export const useIdle = (ms = 10 * ONE_MINUTE_IN_MS) => {
  let timeout = null;

  const refresh = () => {
    window.location.reload();
  };

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(refresh, ms);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  restartAutoReset();

  window.addEventListener('mousemove', onMouseMove);

  return () => {
    if (timeout) {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', onMouseMove);
    }
  };
};
