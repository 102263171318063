import '../../styles/button.scss';
import { Link } from 'react-router-dom';
import Image from './Image';

function Button({ image, type = 'button', link }) {
  const onClick = () => {
    if (!link) {
      return;
    }

    if (link.includes('#')) {
      document
        .getElementById(link.replace('/#', ''))
        .scrollIntoView({ behavior: 'smooth' });
    } else {
      window.open(link);
    }
  };

  if (type === 'link') {
    return (
      <Link
        id="cta_signup_footer_btn"
        to={link}
        className="spec-button"
        onClick={onClick}
        aria-label="General-link"
        style={{ backgroundImage: `url(${image})` }}
      >
        <Image src={image} />
      </Link>
    );
  }

  return (
    <button
      id="cta_signup_footer_btn"
      type={type}
      className="spec-button"
      aria-label="Spec-Button"
      onClick={onClick}
      style={{ backgroundImage: `url(${image})` }}
    >
      <Image src={image} />
    </button>
  );
}

export default Button;
