import { useSelector } from 'react-redux';
import { SeasonStateEnum } from '../../../enums';
import { SeasonEnd } from '../../referrals/sections/SeasonEndScreen';
import { SeasonActive } from './SeasonActive';
import { seasonSelector } from '../../../redux/slices/season.slice';

const SeasonComponentMapper = {
  [SeasonStateEnum.ACTIVE]: <SeasonActive />,
  [SeasonStateEnum.INACTIVE]: <SeasonEnd />,
};

const SeasonComponentMapperProxy = new Proxy(SeasonComponentMapper, {
  get: (target, prop) => {
    if (prop in target) {
      return target[prop];
    }

    return null;
  },
});

export const HeroSection = () => {
  const { state } = useSelector(seasonSelector);

  return SeasonComponentMapperProxy[state];
};
