import { useSelector } from 'react-redux';
import { icons, questsAssets } from '../../../constants/assets';
import { PlayToAirdrop } from '../general';
import { seasonSelector } from '../../../redux/slices/season.slice';

export const SeasonActive = () => {
  const { title } = useSelector(seasonSelector);

  return (
    <section className="relative flex h-[120vh] min-h-[1000px] justify-center bg-questsBg bg-cover bg-center bg-no-repeat">
      <div className='mt-[180px] flex max-w-[500px] flex-col items-center'>
        <div className='relative flex flex-col items-center'>
          <img
            width='600'
            height='380'
            src={questsAssets.logo}
          />
          <h1 className='absolute bottom-5 font-poppins text-xl font-black tracking-wider text-white xxs:bottom-7 xxs:text-2xl sm:bottom-9 sm:text-3xl'>{title}</h1>
        </div>
        <a className='flex flex-col items-center' href='#social_quests'>
          <h2 className='font-poppins text-2xl font-black leading-10 text-newNavDarkBlue sm:text-3xl'>
            Social Quests below
          </h2>
          <img className='-mt-2 size-12' src={icons.doubleArrowBottom} />
        </a>
      </div>

      <PlayToAirdrop />
    </section>
  );
};
