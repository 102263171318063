import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { validateUsername } from '../../context/signIn-signUp/usernameFormValidation';
import { setName } from '../../services/auth/create-username';
import { handleDashboardRequest } from '../../context/dashboard/handleDashboardRequest';
import { authAssets } from '../../constants/assets';

function LegacyUsernameCreate() {
  const [formValues, setFormvalues] = useState({ username: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorFromServer, setErrorFromServer] = useState('');
  const [isErrorFromServer, setIsErrorFromServer] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormvalues({ ...formValues, username: e.target.value });
    setErrorFromServer('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validateUsername(formValues));
    setIsSubmit(true);
    setIsErrorFromServer(true);
  };

  const handleUserRedirect = async () => {
    try {
      const { status, data: fetchedData } = await handleDashboardRequest();
      if (status === 401) {
        navigate('/sign-in');
      } else if (!fetchedData || (!fetchedData.username && fetchedData.legacy === false)) {
        navigate('/create-username');
      } else if (fetchedData.legacy === true && !fetchedData.username) {
        navigate('/legacy-create-username');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeCookiesAndRedirect = () => {
    Cookies.remove('fab_session');
    navigate('/');
  };

  useEffect(() => {
    handleUserRedirect();

    if (Object.keys(formErrors).length === 0 && isSubmit && isErrorFromServer) {
      (async () => {
        try {
          await setName({
            name: formValues.username,
          });

          navigate('/dashboard');
        } catch (e) {
          console.error(e);

          if (e.response.status === 400) {
            const { data: fetchedData } = await e.response;
            if (
              fetchedData.error
              && fetchedData.error.message === 'USERNAME_INCORRECT'
            ) {
              setErrorFromServer('This is not a valid username');
              setIsErrorFromServer(false);
            } else {
              throw new Error('This account already has a username');
            }
          }
        }
      })();
    }

    document.body.style.backgroundColor = 'white';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [formErrors, errorFromServer]);

  return (
    <div className="relative flex h-screen font-objektiv">
      <div className="hidden h-screen w-full max-w-[500px] bg-red-200 lg:block">
        <img
          src={authAssets.bg}
          alt="bg-image"
          class="size-full object-cover object-left"
        />
      </div>
      <div className="flex w-full items-center justify-center p-5">
        <div className="flex w-full min-w-[300px] max-w-[400px] flex-col items-center">
          <div className="h-auto w-full">
            <img width="672" height="122" src={authAssets.pixionLogo} alt="pixionLogo" />
          </div>
          <div className="mb-3 mt-12 w-full text-black">
            <div className="text-xl font-bold">Choose Your Username</div>
            <div className="text-sm">
              Usernames may only contain 3 to 12 letters and numbers.
            </div>
          </div>
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-6 w-full text-black">
              <input
                className="h-14 w-full rounded border-2 border-blue-400 text-center text-lg focus:outline-pixionLogoPink"
                type="text"
                id="username"
                value={formValues.username}
                onChange={handleChange}
              />
              {formErrors.username && (
                <span className="text-error_text">{formErrors.username}</span>
              )}
              {errorFromServer && (
                <span className="text-error_text">{errorFromServer}</span>
              )}
            </div>
            <button
              type="submit"
              className="h-14 w-full rounded bg-pixionLogoPink font-bold text-white"
            >
              UPDATE ACCOUNT
            </button>
          </form>
          <p className='mt-4 w-full text-center text-black'>
            To cancel click
            <button
              className='pl-1 text-blue-400'
              onClick={removeCookiesAndRedirect}
            >
              here
            </button>
          </p>

          <div className="bottom-5 mt-8 px-5 text-center md:absolute md:mt-0">
            <div className="text-black">
              Please contact our{' '}
              <a className="text-blue-400" href="https://pixiongames.helpshift.com/hc/en/3-fableborne/section/9-account/">
                support team
              </a>{' '}
              if you're experiencing account issues.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegacyUsernameCreate;
