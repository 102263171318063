import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getGoldTracker } from '../services/integrations/airdrop';
import { logIn, logOut } from '../redux/slices/navbar.slice';
import Claimed from '../components/gold-essence-checker/Claimed';
import NotClaimed from '../components/gold-essence-checker/NotClaimed';

function GoldEssenceChecker() {
  const [isVisible, setIsVisible] = useState(false);
  const [eligibilityData, setEligibilityData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getGoldTracker();

        dispatch(logIn());
        setIsVisible(true);

        const sortedAirdrops = data?.sort((a, b) => {
          const dateA = new Date(a.lastUpdated);
          const dateB = new Date(b.lastUpdated);
          return dateB - dateA;
        });

        setEligibilityData(sortedAirdrops);
      } catch (e) {
        console.error(e);

        if (e.response.status === 401) {
          navigate('/sign-in');
          dispatch(logOut());
        } else {
          dispatch(logIn());
        }
      }
    })();
  }, []);

  return (
    <div className='min-h-screen bg-blueLinearGradient pt-[60px]'>
      <div className="relative mx-7 min-w-[300px] max-w-[964px] pb-6 pt-[60px] lg:mx-auto">
        <div
          className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`}
        >
          {eligibilityData.length === 0 ? (
            <div className="w-full text-center text-2xl text-white md:text-3xl">
              <h1>No airdrops yet</h1>
            </div>
          ) : (
            <div className='flex w-full flex-col gap-12'>
              {eligibilityData?.map((value, idx) => {
                if (value.reward > 0 || (value.isClaimed && value.reward > 0)) {
                  return (
                    <Claimed
                      data={value}
                      key={idx}
                    />
                  );
                } if (!value.isClaimed || (value.isClaimed && value.reward <= 0)) {
                  return (
                    <NotClaimed
                      data={value}
                      key={idx}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GoldEssenceChecker;
