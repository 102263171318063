import React from 'react';

function Whitepaper() {
  return (
    <div className='relative flex h-screen w-full justify-center bg-blueLinearGradient font-skolar text-white'>
      <div className='absolute top-[120px] flex flex-col items-center justify-center'>
        <h1 className='pb-6'>WHITEPAPER</h1>
        <p className='text-base md:text-lg lg:text-xl'>COMING SOON</p>
      </div>
    </div>);
}

export default Whitepaper;
