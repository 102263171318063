import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSeason } from '../services/api/seasons';
import Spinner from '../components/general/Spinner';
import { SocialQuestsSection } from '../components/quests';
import { useAuthRequired } from '../hooks/useAuthRequired';
import { useIdle } from '../hooks/useIdle';
import { seasonInitialState, setSeason } from '../redux/slices/season.slice';
import { PlayToAirdrop } from '../components/quests/general';

export const Quests = () => {
  const dispatch = useDispatch();

  useIdle();
  useAuthRequired();

  const {
    isError, isLoading, data: season, error,
  } = useSeason();

  useEffect(() => {
    if (season?.id !== seasonInitialState.id) {
      dispatch(setSeason(season));
    }
  }, [season]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      <div className='bg-socialsGradient'>
        <main>
          <div className='pt-[60px]'>
            <PlayToAirdrop />
          </div>
          <SocialQuestsSection />
        </main>
        <footer className='flex h-[100px] items-center justify-center'>
          <p>© 2024 Fableborne. All rights reserved.</p>
        </footer>
      </div>
    </>
  );
};
