import React from 'react';
import { eligibilityAssets } from '../../constants/assets';
import grayBanner from '../../assets/eligibilty-grey-banner-top.png';
import goldEssence from '../../assets/Gold_Essence.svg';
import { airdropDateFormatter } from '../../context/dashboard/airdropDataFormatter';

function NotClaimed({ data }) {
  const { date, time } = airdropDateFormatter(data?.lastUpdated);

  const formattedAmount = data.reward
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div
      className="w-full rounded-lg border border-b-4 border-black bg-dashboard_box_blue drop-shadow-lg md:pb-[40px]">
      {/* Mobile UI until 769px */}
      <div className="relative top-[-2px] mb-3 flex h-[40px] items-center justify-center font-objektiv md:hidden">
        <div className="absolute left-[-2px] w-[105%] sm:w-[103%]">
          <img
            className="h-[40px] w-full"
            src={grayBanner}
            alt="bg-img"
          />
        </div>
        <div className="absolute right-0 flex w-[190px] items-center justify-center">
          <img className="w-full" src={eligibilityAssets.timeBg} alt="bg-img" />
          <div className="absolute flex flex-row text-xs tracking-[1px] text-white">
            <div>{time}</div>
            <div className="mx-1 my-[3px] border-r opacity-50"></div>
            <div className="flex flex-row">
              <p className="pl-[2px]">{date}</p>
              <p className="ml-1 opacity-50">UTC</p>
            </div>
          </div>
        </div>
      </div>
      {/* Desktop UI from 769px */}
      <div className="md:flex md:flex-row">
        <div className="relative top-[-2px] hidden h-[42px] w-full items-center justify-center font-objektiv md:flex md:h-[53px]">
          <div className="absolute left-0 w-[102%]">
            <img
              className="h-auto w-full md:h-[52px]"
              src={grayBanner}
              alt="bg-img"
            />
          </div>
          <p className="absolute left-2 text-xs font-black text-black sm:text-sm md:ml-2 md:text-base lg:text-lg">
            {!data?.isClaimed && data?.reward <= 0
              ? 'NOT ELIGIBLE'
              : 'LEADERBOARD RANK NOT ELIGIBLE'}
          </p>
          <div className="absolute right-0 flex w-[200px] items-center justify-center font-medium md:right-0 md:w-[230px] lg:w-[260px]">
            <img className="w-full" src={eligibilityAssets.timeBg} alt="bg-img" />
            <div className="absolute flex flex-row text-xs tracking-[2px] text-white md:text-sm lg:text-base">
              <div>{time}</div>
              <div className="mx-1 my-[3px] border-r opacity-50"></div>
              <div className="flex flex-row">
                <p className="pl-[2px]">{date}</p>
                <p className="ml-1 opacity-50">UTC</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3 md:mx-8 md:my-6 md:flex md:flex-row">
        <div className="w-full">
          <div className="flex flex-col text-white md:mt-0">
            <h1 className='mb-2 text-xl font-black md:hidden'>
              {!data?.isClaimed && data?.reward <= 0
                ? 'NOT ELIGIBLE'
                : 'LEADERBOARD RANK NOT ELIGIBLE'}
            </h1>
            <p className="w-full font-skolar font-bold opacity-60 md:w-[250px] md:text-xl md:opacity-100">
              {data?.eligibilityText}
            </p>
            <p className="my-2 pr-2 font-objektiv text-sm opacity-60 md:mb-0 md:pr-12 md:opacity-100">
              {data?.description}
            </p>
          </div>
        </div>
        <div className="border-b border-white opacity-50 md:hidden"></div>
        <div className="mt-2 w-full font-objektiv text-white md:mt-0">
          <div className="mb-2 font-bold md:text-lg">
            <p>Rewards:</p>
          </div>
          <div className="border-opacity/20 mb-4 flex flex-row items-center rounded-md bg-inner_box_blue p-2 md:h-[60px]">
            <div className="w-12 md:mr-2">
              <div>
                <img
                  className='opacity-50'
                  src={goldEssence}
                  alt="PrimEssence" />
              </div>
            </div>
            <div className="flex flex-col pl-1 text-xs leading-4 md:text-sm">
              <p className='font-bold'>Gold Essence</p>
              <p>{formattedAmount}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotClaimed;
