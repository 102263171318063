import '../../styles/footerMenu.scss';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Gradient from 'javascript-color-gradient';
import SvgColor from 'react-svg-color';
import Button from './Button';
import { buttons, generalAssets, homePageAssets } from '../../constants/assets';

function FooterMenu({ data }) {
  const [pixionLogo, setPixionLogo] = useState(homePageAssets.pixionLogo);
  const [pixionColor, setPixionColor] = useState('#f6efdf');
  useEffect(() => {
    setPixionLogo(homePageAssets.pixionLogo);
  }, []);

  const year = new Date().getFullYear();

  function colorChange(color1, color2) {
    const gradientArray = new Gradient()
      .setColorGradient(`#${color1}`, color2)
      .getColors();
    const timegap = 300 / gradientArray.length;
    gradientArray.forEach((gr, key) => {
      setTimeout(() => {
        setPixionColor(gr);
      }, key * timegap);
    });
  }

  return (
    <div className="footer-menu">
      <div className="footer-menu-body">
        <div className="footer-menu-container">
          <div className="footer-fableborne__container">
            <a href="/#signup" alt="Fableborne">
              <img src={generalAssets.mainLogo} alt="fableborne" />
            </a>
            <div className="footer-fableborne__button__container">
              <a href="/#signup">
                <img src={buttons.signUp} alt="signupBtn" />
              </a>
            </div>
          </div>
          <div className="footer-pixion-container">
            <a
              href="https://pixiongames.com"
              alt="Pixion Games"
              target="_blank"
              rel="noreferrer"
              aria-label="Link to Pixion games"
            >
              <div
                className="pixion-logo-new"
                onMouseOver={() => colorChange('f6efdf', 'ea5690')}
                onMouseLeave={() => colorChange('ea5690', 'f6efdf')}
              >
                {pixionLogo && (
                  <SvgColor svg={pixionLogo} colors={[pixionColor]} />
                )}
              </div>
            </a>
            <div className="copyright-text">
              ©{year} {data?.attributes?.copyrightText}
            </div>
          </div>
        </div>

        <div className="footer-menu-about">
          <div className="logoSide">
            <a href="/#signup" alt="Fableborne">
              <img src={generalAssets.mainLogo} alt="fableborne" />
            </a>
            <div className="button">
              <Button
                text="Sign up"
                image={buttons.signUp}
                link="/#signup"
                type="link"
              />
            </div>
          </div>
          <div className="pixionSide">
            <a
              href="https://pixiongames.com"
              alt="Pixion Games"
              target="_blank"
              rel="noreferrer"
              aria-label="Link to Pixion games"
            >
              <div
                className="pixion-logo"
                onMouseOver={() => colorChange('f6efdf', 'ea5690')}
                onMouseLeave={() => colorChange('ea5690', 'f6efdf')}
              >
                <SvgColor svg={pixionLogo} colors={[pixionColor]} />
              </div>
            </a>
            <div className="text">
              ©{year} {data?.attributes?.copyrightText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FooterMenu.propTypes = {
  click: PropTypes.func,
};

export default FooterMenu;
