import { useClaimOneTimeQuest, useCompleteOneTimeQuest } from '../../../services/api/seasons';
import { QuestsList } from './QuestList';

export const OneTimeQuests = ({ quests, onRewardClaim, ...props }) => {
  const { mutate: claimMutate } = useClaimOneTimeQuest({
    onSuccess: onRewardClaim,
  });

  const { mutate: completeMutate } = useCompleteOneTimeQuest({
    onSuccess: onRewardClaim,
  });

  if (!quests) {
    return null;
  }

  return (
    <>
      <h1 className='my-7 text-left text-xl text-white xxs:text-2xl sm:text-start sm:text-3xl'>
        ONE-TIME QUESTS
      </h1>
      <QuestsList
        quests={quests}
        onClaim={claimMutate}
        onComplete={completeMutate}
        {...props}
      />
    </>
  );
};
