import { shortifyWallet } from '../../../utils';

export const RegisteredWalletBox = ({
  mainWalletAddress,
  wallet,
  walletDisconnect,
  walletSetMain,
}) => {
  const handleClick = () => {
    setTimeout(() => {
      walletDisconnect();
    }, 500);
  };

  return (
    <li className="mb-2 flex flex-row items-center justify-between font-poppins">
      <div className="flex h-auto w-full flex-wrap items-center justify-between rounded bg-inner_box_blue p-2 md:p-3">
        <p className="text-sm font-bold text-white sm:text-base">
          {shortifyWallet(wallet)}
        </p>
        <div className="flex flex-row">
          <button
            onClick={() => handleClick()}
            className="relative ml-1 flex h-[25px] w-[85px] items-center justify-center bg-redBtn bg-contain bg-no-repeat sm:h-[30px] sm:w-[100px] md:h-[35px] md:w-[119px]"
          >
            <p className="text-shadow-custom absolute text-xxs font-black tracking-wider md:text-xs">
              DISCONNECT
            </p>
          </button>
        </div>
      </div>
      {wallet === mainWalletAddress ? (
        <div className="ml-3 flex h-[35px] w-[52px] items-center justify-center rounded border border-b-[3px] border-black bg-bright_yellow px-2">
          <p className="text-xs font-black text-black">MAIN</p>
        </div>
      ) : (
        <button
          className="ml-3 flex h-[35px] w-[52px] items-center justify-center rounded border border-b-[3px] border-border_bt_shadow border-b-border_gray_100 px-2 hover:bg-bg_gradient_dark_blue"
          onClick={() => walletSetMain(wallet)}
        >
          <p className="text-xs font-black text-border_gray_100 ">MAIN</p>
        </button>
      )}
    </li>
  );
};
