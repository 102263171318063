import React, { useEffect } from 'react';
import { OAuthStrategies } from '../components/socialLogin/sign-in/OAuthStrategies';
import { urls } from '../constants/urls';
import { WalletSignIn } from '../components/socialLogin/sign-in/WalletSIgnIn';
import { authAssets } from '../constants/assets';
import { handleRedirect } from '../context/dashboard/handleRedirect';

function SocialSignUp() {
  useEffect(() => {
    handleRedirect();

    document.body.style.backgroundColor = 'white';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <div className="font-niveu relative flex">
      <div className="hidden h-screen w-full max-w-[500px] bg-white lg:block">
        <img
          src={authAssets.bg}
          alt="bg-image"
          class="size-full object-cover object-left"
        />
      </div>
      <div className="flex w-full items-center justify-center p-5">
        <div className="flex w-full min-w-[300px] max-w-[400px] flex-col items-center">
          <div className="h-auto w-full">
            <img width="672" height="122" src={authAssets.pixionLogo} alt="pixionLogo" />
          </div>
          <div className="mt-12 w-full text-black">
            <div className="text-xl font-bold">Create Account</div>
            <div className="mt-1 text-sm">
              By creating a new account, you agree to follow Pixion Games{' '}
              <a className="text-blue-400" href="/terms">
                Terms & Conditions
              </a>
              ,
              <a className="text-blue-400" href="/privacy">
                {' '}
                Privacy Policy
              </a>{' '}
              and{' '}
              <a className="text-blue-400" href="/eula">
                EULA
              </a>
              .
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col gap-4">
            <OAuthStrategies
              url={`${process.env.REACT_APP_API_ENDPOINT}/${urls.appleLogin}`}
              name="Apple"
              className="bg-black"
              icon={authAssets.apple}
              action='Sign up'
            />
            <OAuthStrategies
              url={`${process.env.REACT_APP_API_ENDPOINT}/${urls.googleLogin}`}
              name="Google"
              className="bg-[#4285F4]"
              icon={authAssets.google}
              action='Sign up'
            />
            <OAuthStrategies
              url={`${process.env.REACT_APP_API_ENDPOINT}/${urls.twitterLogin}`}
              name="X"
              className="bg-black"
              icon={authAssets.x}
              action='Sign up'
            />
            <WalletSignIn name="Wallet" action='Sign up' />
          </div>
          <div className="mt-4 w-full text-center">
            <div className="text-base text-black">
              Already have an account?{' '}
              <a className="text-blue-400" href="/sign-in">
                Sign In
              </a>
            </div>
          </div>
          <div className="flex h-14 items-end">
            <a href="https://twitter.com/fableborne">
              <img className="mr-3 h-auto w-5" src={authAssets.x} alt="xLogo" />
            </a>
            <a href="https://discord.com/invite/fableborne">
              <img
                className="ml-3 h-auto w-[27px]"
                src={authAssets.discordLogo}
                alt="discordLogo"
              />
            </a>
          </div>
          <div className="p-5 text-center text-sm">
            <div className="text-black">
              Please contact our{' '}
              <a
                className="text-blue-400"
                href="https://pixiongames.helpshift.com/hc/en/3-fableborne/section/9-account/"
              >
                support team
              </a>{' '}
              if you're experiencing account issues.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialSignUp;
