import { createSlice } from '@reduxjs/toolkit';

export const seasonInitialState = {
  id: -1,
  title: '',
  state: '',
  playToAirdrop: {
    id: 1,
    startDate: '',
    endDate: '',
    appleStoreUrl: '',
    googlePlayUrl: '',
  },
};

const seasonSlice = createSlice({
  name: 'season',
  initialState: seasonInitialState,
  reducers: {
    setSeason: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setSeason } = seasonSlice.actions;
export const seasonSelector = (state) => state.season;

export default seasonSlice.reducer;
