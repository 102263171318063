import '../../styles/trailer.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Image from '../general/Image';
import VideoPlayer from '../general/VideoPlayer';
import { homePageAssets } from '../../constants/assets';

function Trailer() {
  const videoLink = 'https://www.youtube.com/watch?v=H-3AT7YLtkQ';
  const externalVideo = videoLink || false;

  const avatarIamge = homePageAssets.trailer.fablebornIcon ? (
    <Image src={homePageAssets.trailer.fablebornIcon} alt="avatar" style={{ width: '100%' }} />
  ) : (
    ''
  );

  const video = externalVideo ? (
    <VideoPlayer videoLink={videoLink} />
  ) : (
    avatarIamge
  );

  return (
    <div className="trailer">
      <div className="tailer-container">
        <h1 className="trailer-title" style={{ zIndex: '120' }} id="about">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            Enter the Shatterlands
          </AnimationOnScroll>
        </h1>

        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOnce={true}
          className="avatar"
        >
          {video}
        </AnimationOnScroll>
      </div>
    </div>
  );
}

export default Trailer;
