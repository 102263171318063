import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from '../components/general/PageNotFound';
import Terms from '../components/sections/Terms';
import Eula from '../components/sections/Eula';
import Privacy from '../components/sections/Privacy';
import Authentication from '../pages/Authentication';
import VerifyEmailMessage from '../components/marketplace-general/VerifyEmailMessage';
import AirdropChecker from '../pages/AirdropChecker';
import GoldEssenceChecker from '../pages/GoldEssenceChecker';
import SocialSignup from '../pages/Sign-up';
import SocialSignin from '../pages/Sign-in';
import CreateUsername from '../components/socialLogin/UsernameCreate';
import Dashboard from '../pages/Dashboard';
import LegacyUsernameCreate from '../components/socialLogin/LegacyUsernameCreate';
import ErrorsPage from '../pages/Errors';
import Whitepaper from '../pages/Whitepaper';
import { Quests } from '../pages/Quests';
import FableborneHomepage from '../pages/FableborneHomepage';
import Forge from '../pages/Forge';

const AppRoutes = ({
  terms, eula, privacy, footer,
}) => (
  <Routes>
    <Route path="/" element={<FableborneHomepage />} />
    <Route path="/errors" element={<ErrorsPage />} />
    <Route path="/*" element={<PageNotFound />} />
    <Route path="/terms" element={<Terms data={terms} footer={footer} />} />
    <Route path="/eula" element={<Eula data={eula} footer={footer} />} />
    <Route path="/privacy" element={<Privacy data={privacy} footer={footer} />} />
    <Route path="/auth" element={<Authentication />} />
    <Route path="/auth/confirm" element={<VerifyEmailMessage />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/airdrop" element={<AirdropChecker />} />
    <Route path="/gold-essence-checker" element={<GoldEssenceChecker />} />
    <Route path="/sign-up" element={<SocialSignup />} />
    <Route path="/sign-in" element={<SocialSignin />} />
    <Route path="/create-username" element={<CreateUsername />} />
    <Route path="/legacy-create-username" element={<LegacyUsernameCreate />} />
    <Route path="/whitepaper" element={<Whitepaper />} />
    <Route path="/quests" element={<Quests />} />
    <Route path="/forge" element={<Forge />} />
    {/* <Route path="/referrals" element={<ReferralsPage />} /> */}
  </Routes>
);

export default AppRoutes;
