import { twMerge } from 'tailwind-merge';
import { Box } from '../shared/Box';
import { PointsBox } from '../shared/PointsBox';
import { essenceAssets } from '../../../constants/assets';

const resourcesName = {
  essence: 'Primordial Essence',
  fable: 'Gold Essence',
  referral: 'Silver Essence',
  social: 'Bronze Essence',
};

const essence = {
  essence: essenceAssets.primordial,
  fable: essenceAssets.gold,
  referral: essenceAssets.silver,
  social: essenceAssets.bronze,
};

const Link = ({ walletAddress }) => (
  <a
    href="/airdrop"
    className={twMerge(!walletAddress ? 'pointer-events-none bg-grayBtnLarge h-[27px] md:h-[36px]' : 'bg-greenBtnBig custom-color__brightness h-[31px] md:h-[41px]', 'relative flex items-center justify-center w-[150px] bg-contain bg-no-repeat rounded text-xxs font-black md:text-sm md:w-[200px]')}
  >
    <p className='text-shadow-custom absolute tracking-wider'>
      PRIMORDIAL CHECKER
    </p>
  </a>
);

const GoldButton = ({ walletAddress }) => (
  <a
    href="/gold-essence-checker"
    className={twMerge(!walletAddress ? 'pointer-events-none bg-grayBtnLarge h-[27px] md:h-[36px]' : 'bg-greenBtnBig custom-color__brightness h-[31px] md:h-[41px]', 'relative flex items-center justify-center w-[150px] bg-contain bg-no-repeat rounded text-xxs font-black md:text-sm md:w-[200px]')}
  >
    <p className='text-shadow-custom absolute tracking-wider'>
      GOLD CHECKER
    </p>
  </a>
);

export const PointsSection = ({ resources = {}, walletAddress }) => (
  <Box>
    <div className="flex flex-col">
      <div className="mb-2 text-left font-skolar text-base font-black tracking-wider md:text-lg lg:text-xl">
        POINTS INVENTORY
      </div>
      <div className="flex flex-col rounded bg-lightBlueLinearGradient p-4 sm:flex-row">
        <ul className="flex w-full flex-col sm:w-[70%]">
          {Object.entries(resourcesName).map(([key, resourceName]) => (
            <PointsBox
              key={key}
              resource={resources[key]}
              resourceName={resourceName}
              essence={essence[key]}
            />
          ))}
        </ul>
        <div className="flex w-full flex-col items-start gap-4 sm:w-[30%] sm:items-end sm:justify-start">
          <Link
            walletAddress={walletAddress}
          />
          <GoldButton
            walletAddress={walletAddress}
          />
        </div>
      </div>
    </div>
  </Box>
);
