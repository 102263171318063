const cookieReducer = (state, action) => {
  switch (action.type) {
    case 'SET_VISIBLE':
      return {
        ...state,
        visible: 'show',
      };
    case 'SET_HIDDEN':
      return {
        ...state,
        visible: 'hidden',
      };
    case 'SET_COOKIE':
      return {
        ...state,
        visible: action.payload,
      };
    default:
      return state;
  }
};

export default cookieReducer;
