import { useSelector } from 'react-redux';
import { MyPoints } from '../general';
import { useQuests } from '../../../services/api/seasons';
import Spinner from '../../general/Spinner';
import { LeaderboardTypeEnum } from '../../../enums';
import { useLeaderboard } from '../../../services/api/leaderboard';
import { seasonSelector } from '../../../redux/slices/season.slice';
import { DailyQuests } from '../general/DailyQuests';
import { OneTimeQuests } from '../general/OneTimeQuests';

export const SocialQuestsSection = () => {
  const { id: seasonId } = useSelector(seasonSelector);

  const {
    isError, isLoading, error,
    data: {
      quests, dailyQuests, userPoints, pointsMultiplier,
    } = {},
    refetch: questsRefetch,
  } = useQuests(seasonId);

  const state = 'BRONZE';
  const leaderboardType = LeaderboardTypeEnum.SOCIAL;

  const {
    data: leaderboardData,
    isLoading: isLeaderboardLoading,
    refetch: leaderboardRefetch,
  } = useLeaderboard(seasonId, leaderboardType);

  const areClaimedQuests = quests?.some((quest) => quest.state === 'CLAIMED');

  const onRewardClaim = () => {
    questsRefetch();
    leaderboardRefetch();
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }

  return (
    <section className='items-start px-1 py-5 xxs:px-3 md:px-10 md:pt-10' id='social_quests'>
      <div className='w-full'>
        <div className="flex w-full flex-col flex-wrap items-center justify-center gap-14 lg:flex-row lg:items-start xl:justify-evenly" >
          <section className='flex w-full flex-col md:w-3/4 md:min-w-[580px] xl:basis-6/12'>
            <DailyQuests
              quests={dailyQuests}
              onRewardClaim={onRewardClaim}
              seasonId={seasonId}
            />
            <OneTimeQuests
              quests={quests}
              onRewardClaim={onRewardClaim}
              seasonId={seasonId}
            />
          </section>
          <MyPoints
            points={userPoints}
            headerMultiplier={pointsMultiplier}
            seasonId={seasonId}
            areClaimedQuests={areClaimedQuests}
            leaderboardData={leaderboardData}
            isLoading={isLeaderboardLoading}
            state={state}
          />
        </div>
      </div>
    </section>
  );
};
