import { twMerge } from 'tailwind-merge';

export const Badge = ({ children, cn }) => (
  <span className={twMerge(
    'px-1 py-1 rounded text-sm text-center tracking-wide border-b-black border-b-2 sm:px-3 sm:text-lg',
    cn,
  )}
  >
    {children}
  </span>
);
