import { configureStore } from '@reduxjs/toolkit';
import navBarReducer from './slices/navbar.slice';
import settingsReducer from './slices/settings.slice';
import seasonReducer from './slices/season.slice';

export const store = configureStore({
  reducer: {
    navBar: navBarReducer,
    settings: settingsReducer,
    season: seasonReducer,
  },
});
