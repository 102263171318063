import { Box } from '../shared/Box';

export const ProfileBox = ({ children }) => (
  <div className='flex h-auto w-full select-text flex-wrap items-center justify-between rounded bg-inner_box_blue p-2 md:p-3'>
    {children}
  </div>
);

export const MyProfileSection = ({ username, pixionId }) => (
  <Box label="MY PROFILE">
    <div className='flex flex-col gap-4 font-skolar'>
      <ProfileBox>USERNAME: {username}</ProfileBox >
      <ProfileBox>PIXION ID: {pixionId}</ProfileBox >
    </div>
  </Box>
);
