import { twMerge } from 'tailwind-merge';
import { essenceAssets } from '../../../constants/assets';
import { NFTMultiplierBadge, NFTMultipliersSection, NFTMaxMultiplierBadge } from '../../nft-multipliers';
import { GrandPrizeBadge } from './GrandPrizeBadge';
import { Leaderboard } from '../../leaderboard';
import { useFeature } from '../../../hooks/useFeature';
import { useMultipliers } from '../../../services/api/multipliers';
import { DEFAULT_MAX_MULTIPLIER } from '../../../constants';

export const MyPoints = ({
  points, seasonId, headerMultiplier,
  areClaimedQuests, leaderboardData, isLoading,
  state,
}) => {
  const {
    data: {
      multipliers = [],
      maxMultiplier = DEFAULT_MAX_MULTIPLIER,
    } = {},
  } = useMultipliers(seasonId);

  const hasNFTMultipliers = useFeature('NFTMultipliers');
  const hasLeaderboard = useFeature('Leaderboard');
  const isQuestsPage = state === 'BRONZE';
  const isNeededToRenderNFTMultipliers = hasNFTMultipliers && isQuestsPage;

  const isEligible = areClaimedQuests
    && leaderboardData?.leaderboardTopRewarded >= leaderboardData?.leaderboard?.userEntry?.position;
  const isMaxMultiplier = headerMultiplier === maxMultiplier;

  return (
    <div className="relative flex w-full flex-col gap-4 self-center rounded-lg bg-myPointsGradient px-2 py-4 text-xl font-black text-white sm:p-7 sm:px-4 md:w-3/4 md:min-w-[580px] xl:mt-[6.75rem] xl:min-w-[457px] xl:basis-4/12 xl:self-auto">
      <div className='flex items-center gap-5 font-poppins'>
        <h2 className={twMerge(
          'text-lg font-black xxs:text-2xl sm:text-2xl 2xl:text-3xl',
          isMaxMultiplier && 'xxs:text-1xl',
        )}>
          {`${state} ESSENCE`}
        </h2>
        {/* Commenting out because of `FAB-9015-remove-points-info-buttons-from-quests-and-referrals` */}
        {/*
          <span className='bg-[#76C7FF] px-3 py-1 rounded text-black tracking-wide border-b-black border-b-2' >
            Points info
          </span>
        */}
        {isNeededToRenderNFTMultipliers && (
          isMaxMultiplier
            ? <NFTMaxMultiplierBadge multiplier={headerMultiplier} />
            : <NFTMultiplierBadge multiplier={headerMultiplier} isHeader />
        )}
      </div>

      <div className='relative flex w-[85%] items-center justify-between rounded-md bg-black px-5 py-2'>
        <p className='text-3xl sm:text-4xl'>{points?.toLocaleString()}</p>
        <img src={isQuestsPage ? essenceAssets.bronze : essenceAssets.silver} className='absolute -right-10 mb-1 size-[90px]' />
      </div>

      {hasLeaderboard && isQuestsPage && (
        <GrandPrizeBadge
          topRewarded={leaderboardData?.leaderboardTopRewarded}
          isEligible={isEligible}
        />
      )}

      {isNeededToRenderNFTMultipliers && (
        <NFTMultipliersSection
          multipliers={multipliers}
          maxMultiplier={maxMultiplier}
        />
      )}

      {hasLeaderboard && (
        <Leaderboard
          data={leaderboardData}
          isQuestPage={isQuestsPage}
          isLoading={isLoading}
          isEligible={isEligible}
        />)
      }
    </div>
  );
};
