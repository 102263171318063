export const websiteContent = {
  eula: 'eula-page-content.json',
  footer: 'footer-section-content.json',
  investorsLogos: 'investors-logos-content.json',
  nft: 'nft-section-content.json',
  privacy: 'privacy-page-content.json',
  referralRules: 'referral-rules-content.json',
  social: 'socials-section-content.json',
  terms: 'terms-page-content.json',
  virtues: 'virtues-section-content.json',
  titles: 'website-page-content.json',
  aboutForge: 'about-forge-section-content.json',
  infoForge: 'info-forge-section-content.json',
};
