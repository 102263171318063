import { twMerge } from 'tailwind-merge';
import { essenceAssets, icons } from '../../../../constants/assets';
import { QuestStateEnum } from '../../../../enums';
import { Badge } from '../../../general/Badge';
import { QuestsListButton } from './QuestListButton';
import { QUEST_STATE_MAPPER } from '../../../../constants';

export const QuestListItem = ({ quest, isDaily, ...props }) => {
  const isClaimed = quest?.state === QuestStateEnum.CLAIMED;
  const isNew = isDaily && !isClaimed;

  return (
    <li
      className={twMerge(
        'flex relative justify-between p-2.5 border-black border-2 border-b-4 bg-bright_dark_blue rounded-md font-black font-poppins text-white text-xl',
        quest?.state === QuestStateEnum.CLAIMED && 'opacity-80 bg-[#003A75]',
      )}
    >
      {isNew && (
        <Badge cn={'bg-[#FFDC30] text-black absolute -top-3 -left-3 md:-top-3 md:-left-8 p-1 sm:py-0 -rotate-[20deg]'} >
          NEW
        </Badge>
      )}
      <div className='flex w-1/2 items-center gap-2 sm:ml-10 sm:w-auto md:ml-0'>
        <img className='hidden size-10 md:block' src={icons.trophy} />
        <p className='xs:text-base text-sm font-medium sm:text-lg sm:font-bold'>{quest.title}</p>
      </div>
      <div className='flex items-center text-white'>
        <p className='ml-2 text-lg sm:text-xl'>{quest.pointsReceived || quest.points}</p>
        <img src={essenceAssets.bronze} className='mr-2 size-10' />
        {quest?.state === QuestStateEnum.CLAIMED
          ? <p className="w-20 text-center text-[#1A68BF] md:w-24">{QUEST_STATE_MAPPER[quest.state]}</p>
          : <QuestsListButton quest={quest} {...props} />
        }
      </div>
    </li>
  );
};
