export const errorsMap = new Map([
  ['WALLET_NOT_LINKED', 'Wallet not linked'],
  ['WALLET_ALREADY_MAIN', 'Wallet already Main'],
  ['WALLET_ALREADY_LINKED', 'Wallet already linked'],
  ['INVALID_WALLET_ADDRESS', 'Invalid wallet address'],
  ['SIGNED_MESSAGE_NOT_FOUND', 'Signed message not found'],
  ['SIGNATURE_INCORRECT', 'Signature incorect'],
  ['ACCOUNT_CANT_UNLINK_LAST_SOCIAL', 'Apple/Google/X and Wallet is the only way you used to register. Please link another account and try again.'],
  ['TWITTER_NOT_LINKED', 'You must link your account to X to start social quests'],
  ['DISCORD_NOT_LINKED', 'You must connect your Discord account'],
  ['RGP_CANNOT_USE_YOUR_OWN_CODE', 'You cannot use your own code'],
  ['RGP_INVALID_OPERATION_FOR_STATE', 'Looks like something went wrong. Please refresh the page and try again later'],
  ['RGP_WRONG_USER_FOR_THIS_REWARD', 'Wrong user for this reward'],
  ['RGP_TWITTER_NOT_LINKED', 'You must link your account to X to start social quests'],
  ['RGP_WALLET_NOT_LINKED', 'You must connect and SIGN your Wallet to start referral raids'],
]);
