import { DateTime } from 'luxon';
import { useFeature } from '../../../hooks/useFeature';
import { useClaimDailyQuest, useCompleteDailyQuest } from '../../../services/api/seasons';
import { QuestsList } from './QuestList';
import { DailyQuestsCountdown } from './DailyQuestsCountdown';

export const DailyQuests = ({ quests, onRewardClaim, ...props }) => {
  const { mutate: claimMutate } = useClaimDailyQuest({
    onSuccess: onRewardClaim,
  });

  const { mutate: completeMutate } = useCompleteDailyQuest({
    onSuccess: onRewardClaim,
  });

  const isDisabled = !useFeature('DailyQuests');

  if (isDisabled || !quests) {
    return null;
  }

  const activeDailyQuests = quests.filter((quest) => DateTime.fromISO(quest.endDate) > Date.now());
  const isDailyEmpty = activeDailyQuests?.length === 0;

  return (
    <>
      <div className='flex gap-3'>
        <h1 className='my-7 text-left text-xl text-white xxs:text-2xl sm:text-start sm:text-3xl'>
          DAILY QUESTS
        </h1>
        {!isDailyEmpty && <DailyQuestsCountdown endDate={quests?.at(0)?.endDate} />}
      </div>
      <QuestsList
        quests={activeDailyQuests}
        onClaim={claimMutate}
        onComplete={completeMutate}
        isDaily
        {...props}
      />
    </>
  );
};
