import React from 'react';
import { Link } from 'react-router-dom';
import { navBarAssets } from '../../constants/assets';

function MobileBurgerMenu({
  toggleMobileMenu,
  isMobileMenuOpen,
  isLoggedIn,
  click,
}) {
  return (
    <div className="flex h-full">
      <div onClick={toggleMobileMenu} className="mr-2 flex items-center">
        <div
          className={`${isMobileMenuOpen ? 'fixed left-0 top-[60px] z-40 h-screen w-screen backdrop-blur' : 'hidden'}`}
        ></div>
        <div className="relative z-50 font-objektiv tracking-[3px] text-white">
          <div className={`${isMobileMenuOpen ? 'open' : ''}`}>
            <div className="menu-btn__burger"></div>
          </div>
          <ul
            className={`absolute -right-5 top-[31px] w-[270px] overflow-hidden rounded-b-sm bg-newNavDarkBlue ${isMobileMenuOpen
              ? 'h-[245px] transition-all duration-300 ease-in-out'
              : 'h-0 transition-all duration-300 ease-in-out'}`}
          >
            <li className="block border-b border-[#747474] px-4 py-2 font-bold">
              <Link onClick={click} to="/" data-link="top-of-page">
                HOME
              </Link>
            </li>
            <li className="block border-b border-[#747474] px-4 py-2 font-bold">
              <Link onClick={click} to="/#pixion" data-link="pixion">
                PIXION
              </Link>
            </li>
            <li className="block border-b border-[#747474] px-4 py-2 font-bold">
              <Link to="/whitepaper">
                WHITEPAPER
              </Link>
            </li>
            <li className="block border-b border-[#747474] px-4 py-2 font-bold">
              <Link to="/quests">
                QUESTS
              </Link>
            </li>
            <li className="block border-b border-[#747474] px-4 py-2 font-bold">
              <Link to="/forge">
                FORGE
              </Link>
            </li>
            {/* <li className="block border-b border-[#747474] px-4 py-2 font-bold">
              <Link to="/referrals">
                BOSS BATTLE
              </Link>
            </li> */}
            <li className="block px-4 py-2 font-bold">
              <a href={isLoggedIn ? '/dashboard' : '/sign-in'}>
                {isLoggedIn ? (
                  <div className="flex items-center">
                    <div>ACCOUNT</div>
                    <img
                      className="ml-[2px] size-[15px]"
                      src={navBarAssets.account}
                      alt="AccountIconWhite"
                    />
                  </div>
                ) : (
                  <div>SIGN IN</div>
                )}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MobileBurgerMenu;
