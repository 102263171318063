import '../../styles/whyFableborne.scss';
import { ReactBasicScroll } from 'react-basic-scroll';
import htmlParse from 'html-react-parser';
import { parse } from 'marked';
import { useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Image from '../general/Image';
import { buttons, homePageAssets } from '../../constants/assets';

function AboutForge({ data }) {
  const [mobileActive, setMobileActive] = useState(0);
  const bg = homePageAssets.virtuesBg;

  const islandSet = {
    from: 2000,
    to: 2800,
    props: {
      '--scIs': {
        from: '0.7',
        to: '1',
      },
      '--transIs': {
        from: '-100px',
        to: '0',
      },
    },
  };

  const onClick = (e) => {
    e.preventDefault();
    const id = e.currentTarget.id.replaceAll('tab', '');
    e.currentTarget.classList.add('active');
    document.getElementById(`text${id}`).classList.add('active');
    const tabs = document.querySelectorAll('.icon-tab');
    const texts = document.querySelectorAll('.text');
    tabs.forEach((tab) => {
      const tabId = `tab${id}`;
      if (tab.id !== tabId) {
        tab.classList.remove('active');
      }
    });
    texts.forEach((text) => {
      const textId = `text${id}`;
      if (text.id !== textId) {
        text.classList.remove('active');
      }
    });
  };

  const onMobileClickLeft = (e) => {
    e.preventDefault();
    if (mobileActive === 0) {
      return setMobileActive(0);
    }
    return setMobileActive((prevState) => prevState - 1);
  };
  const onMobileClickRight = (e) => {
    e.preventDefault();
    if (data.slide.length - 1 > mobileActive) {
      return setMobileActive((prevState) => prevState + 1);
    }
    return setMobileActive(mobileActive);
  };

  return (
    <div className="virtues !h-[750px] 2xl:!h-[966px]" id="virtues" style={{ background: `url(${bg})` }}>
      <div className="why-body">
        <div className="island">
          <ReactBasicScroll config={islandSet}>
            <Image
              src={homePageAssets.virtuesForeground}
              alt="island"
              style={{
                transform: 'translateY(var(--transIs)) scale(var(--scIs))',
                willChange: 'transform',
              }}
            />
          </ReactBasicScroll>
        </div>
        <div className="content-frame">
          <div className="content">
            <h1 className="content-title mb-3">
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                FABLEBORNE FORGE
              </AnimationOnScroll>
            </h1>
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
              <div className="content-tabs">
                {data?.slide?.map((item, key) => {
                  const { header, id } = item;
                  const active = key === 0 ? ' active' : '';

                  return (
                    <div
                      className={`icon-tab${active}`}
                      id={`tab${id}`}
                      key={`tabs${id}`}
                      onClick={onClick}
                    >
                      <h2 className="hidden text-xl font-black uppercase text-darker_font_blue opacity-100 md:block md:text-2xl lg:text-3xl">
                        {header}
                      </h2>
                    </div>
                  );
                })}
              </div>
              <div className="content-mobile-tabs">
                {mobileActive !== 0 && (
                  <a href="#" className="leftSlider !top-5">
                    <img src={buttons.btnLeft} alt="left" onClick={onMobileClickLeft} />
                  </a>
                )}
                {mobileActive < data.slide.length - 1 && (
                  <a href="#" className="rightSlider !top-5">
                    <img
                      src={buttons.btnRight}
                      alt="right"
                      onClick={onMobileClickRight}
                    />
                  </a>
                )}
                {data?.slide?.map((item, key) => {
                  const { header, id } = item;
                  const active = key === mobileActive ? ' active' : '';

                  return (
                    <div
                      className={`icon-tab${active}`}
                      id={`tab${id}`}
                      key={`tabs${id}`}
                    >
                      <h2 className="mt-3 text-xl font-black uppercase text-darker_font_blue opacity-100 md:hidden">
                        {header}
                      </h2>
                    </div>
                  );
                })}
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
              <div className="content-texts">
                {data?.slide?.map((item, key) => {
                  const { id, text } = item;
                  const active = key === mobileActive ? ' active' : '';
                  return (
                    <div
                      className={`text${active} !font-bold`}
                      id={`text${id}`}
                      key={`texts${id}`}
                    >
                      {htmlParse(parse(text))}
                    </div>
                  );
                })}
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutForge;
