import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DEFAULT_ERROR_MSG } from '../constants';
import { setIsLoggedIn } from '../redux/slices/navbar.slice';
import { handleDashboardRequest } from '../context/dashboard/handleDashboardRequest';

export default function ErrorsPage() {
  const [errorMsg, setErrorMsg] = useState('');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const errorMessage = searchParams.get('message') ?? DEFAULT_ERROR_MSG;

  useEffect(() => {
    (async () => {
      const { status } = await handleDashboardRequest();
      if (status !== 401 || status !== 200) {
        setErrorMsg('Something went wrong');
      } else {
        setErrorMsg(errorMessage);
      }

      dispatch(setIsLoggedIn(status === 200));
    })();
  }, []);

  return (
    <main className="place-items-cente grid h-screen min-h-full bg-blueLinearGradient px-6 py-24 font-skolar sm:py-32 lg:px-8">
      <div className="min-w-[320px] px-5 text-center">
        <h1 className="mt-4 break-words text-3xl font-bold tracking-tight text-white sm:text-5xl">
          {errorMsg}
        </h1>
        <p className="mt-6 text-base leading-7 text-white md:text-lg">
          Sorry, we can't process your request. Please, try again later.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to={'/dashboard'}
            href="/dashboard"
            className="rounded-md bg-grape px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>
  );
}
