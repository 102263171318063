import { Blocks } from 'react-loader-spinner';

function Spinner() {
  return (
    <div className="absolute left-0 top-0 z-[999] h-screen w-screen bg-newNavDarkBlue" id="spinner">
      <div className="absolute inset-0 flex items-center justify-center">
        <Blocks
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="blocks-loading"
          wrapperClass="blocks-wrapper"
          visible
        />
      </div>
    </div>
  );
}

export default Spinner;
