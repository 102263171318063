import { useQuery } from '@tanstack/react-query';
import { instance } from '../../instance';
import { urls } from '../../../constants/urls';
import { seasonInitialState } from '../../../redux/slices/season.slice';

const getLeaderboard = async (seasonId, type) => {
  const { data } = await instance.get(`/${urls.leaderboard(seasonId, type)}`);

  return data;
};

export const useLeaderboard = (seasonId, type) => useQuery({
  queryKey: [urls.leaderboard(seasonId, type)],
  queryFn: () => getLeaderboard(seasonId, type),
  retry: false,
  enabled: seasonId !== seasonInitialState.id,
});
