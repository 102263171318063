import React, { useContext, useState, useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import CookieContext from '../../context/cookie/CookieContext';

function Cookie() {
  const [isUsingBrave, setIsUsingBrave] = useState(false);
  const { visible, dispatch2 } = useContext(CookieContext);
  const tagManagerArgs = {
    gtmId: 'GTM-57VZBBFS',
  };

  const cookieConsent = getCookieConsentValue('FableborneCookiesSetNow');

  function isBrave() {
    if (window.navigator.brave !== undefined) {
      if (window.navigator.brave.isBrave.name === 'isBrave') {
        return setIsUsingBrave(true);
      }
      return setIsUsingBrave(false);
    }
    return setIsUsingBrave(false);
  }

  const setConsent = (consent) => {
    const consentMode = {
      functionality_storage: consent.necessary ? 'granted' : 'denied',
      security_storage: consent.necessary ? 'granted' : 'denied',
      ad_storage: consent.marketing ? 'granted' : 'denied',
      analytics_storage: consent.analytics ? 'granted' : 'denied',
      personalization: consent.preferences ? 'granted' : 'denied',
    };
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
  };

  const onClickAccept = () => {
    setConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
    });
    dispatch2({ type: 'SET_HIDDEN' });
    TagManager.initialize(tagManagerArgs);
  };
  const onClickDecline = () => {
    setConsent({
      necessary: false,
      analytics: false,
      preferences: false,
      marketing: false,
    });
    dispatch2({ type: 'SET_HIDDEN' });
    if (cookieConsent === 'true') {
      window.location.reload(false);
    }
  };

  useEffect(() => {
    isBrave();
  }, [isUsingBrave]);

  return (
    <>
      <CookieConsent
        location="center"
        cookieName="FableborneCookiesSetNow"
        containerClasses={`cookie-wrapper${isUsingBrave ? ' usingBrave' : ''}`}
        buttonText="Accept additional cookies"
        onAccept={onClickAccept}
        onDecline={onClickDecline}
        buttonWrapperClasses="buttons-container"
        buttonClasses="cookie-button accept-button"
        declineButtonClasses="cookie-button decline-button"
        visible={visible}
        overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
        enableDeclineButton
        declineButtonText="Reject additional cookies"
        overlay
        overlayClasses={`${isUsingBrave === true ? 'cookie-overlay' : ''}`}
      >
        <div className="cookie-container">
          <h1 className="cookie-header">Cookies on FABLEBORNE.COM</h1>
          <div className="cookies-content-container">
            <p>We use essential cookies to make this website work.</p>
            <p>
              We'd like to set additional cookies to understand how you use
              FABLEBORNE.COM, remember your settings and improve our website.
            </p>
            <p>
              We also use cookies set by other sites to help us deliver content
              from their services.
            </p>
          </div>
        </div>
      </CookieConsent>
    </>
  );
}

export default Cookie;
