import { useQueries } from '@tanstack/react-query';
import { contentInstance } from '../../contentInstance';

const getContent = async (url) => {
  const { data } = await contentInstance.get(url);

  return data;
};

export const useContent = (urls) => useQueries({
  queries: urls.map((url) => ({
    queryKey: [url],
    queryFn: () => getContent(url),
  })),
  combine: (results) => ({
    data: results.map((result) => result.data),
    isLoading: results.some((result) => result.isLoading),
  }),
});
