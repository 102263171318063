import { useNavigate } from 'react-router-dom';
import { handleDashboardRequest } from './handleDashboardRequest';

export const handleRedirect = async () => {
  const navigate = useNavigate();

  const { status } = await handleDashboardRequest();

  if (status === 200) {
    navigate('/dashboard');
  }
};
