import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useSignMessage } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getMessageForWalletLogin, walletSignIn } from '../../../services/integrations/wallet';
import { authAssets } from '../../../constants/assets';

export const WalletSignIn = ({ name, action = 'Sign in' }) => (
  <ConnectButton.Custom>
    {({
      account,
      chain,
      openConnectModal,
      mounted,
    }) => {
      const [hasTriedToConnect, setHasTriedToConnect] = useState(false);
      const connected = !!(account && chain);
      const navigate = useNavigate();
      const { signMessageAsync } = useSignMessage();

      const clickHandler = () => {
        if (!connected) {
          openConnectModal();
        }

        setHasTriedToConnect(true);
      };

      useEffect(() => {
        (async () => {
          if (connected && hasTriedToConnect) {
            const accountAddress = account.address;

            const { data } = await getMessageForWalletLogin(accountAddress);
            const message = data?.message;

            const signature = await signMessageAsync({
              account: accountAddress,
              message,
            });

            await walletSignIn(accountAddress, { signature });
            navigate('/dashboard');
          }
        })();
      }, [connected, hasTriedToConnect]);

      return (
        <button
          onClick={clickHandler}
          className="flex h-[80px] w-full items-center rounded-sm bg-blue-400 p-2"
        >
          <div className='size-[64px] rounded-sm bg-white p-2.5'>
            <img
              className='size-full'
              src={authAssets.wallet}
              alt={'wallet-icon-logo'}
            />
          </div>

          <p className="ml-6 text-2xl text-white">
            {mounted ? `${action} with ${name}` : 'Loading...'}
          </p>
        </button>
      );
    }}
  </ConnectButton.Custom>
);
