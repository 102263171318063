import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Eligible from '../components/marketplace-general/airdropCheker/Eligible';
import NotEligible from '../components/marketplace-general/airdropCheker/NotEligible';
import RewardsReceived from '../components/marketplace-general/airdropCheker/RewardsReceived';
import NoRewardsReceived from '../components/marketplace-general/airdropCheker/NoRewardsReceived';
import primEssence from '../assets/marketplace/Primmordial essence icon.png';
import { getAirdrops } from '../services/integrations/airdrop';
import { logIn, logOut } from '../redux/slices/navbar.slice';

function AirdropChecker() {
  const [isVisible, setIsVisible] = useState(false);
  const [eligibilityData, setEligibilityData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getAirdrops();

        dispatch(logIn());
        setIsVisible(true);

        const sortedAirdrops = data.airdrops.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });

        setEligibilityData(sortedAirdrops);
      } catch (e) {
        console.error(e);

        if (e.response.status === 401) {
          navigate('/auth');
          dispatch(logOut());
        } else {
          dispatch(logIn());
        }
      }
    })();
  }, []);

  return (
    <div className='bg-blueLinearGradient'>
      <div className="relative mx-7 min-w-[300px] max-w-[964px] pb-6 pt-[120px] lg:mx-auto">
        <div
          className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`}
        >
          {eligibilityData.length === 0 ? (
            <div className="w-full text-center text-2xl text-white md:text-3xl">
              <h1>No airdrops yet</h1>
            </div>
          ) : (
            <div className='flex w-full flex-col gap-12'>
              {eligibilityData?.map((value, index) => {
                if (value.distributed === false && value.eligible === true) {
                  return (
                    <div key={index} className='w-full'>
                      <Eligible
                        data={value}
                        primEssence={primEssence}
                      />
                    </div>
                  );
                } if (
                  value.distributed === false
                  && value.eligible === false
                ) {
                  return (
                    <div key={index} className='w-full'>
                      <NotEligible
                        data={value}
                        primEssence={primEssence}
                      />
                    </div>
                  );
                } if (
                  value.distributed === true
                  && value.eligible === true
                ) {
                  return (
                    <div key={index} className="w-full md:ml-auto md:max-w-[900px]">
                      <RewardsReceived data={value} primEssence={primEssence} />
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className="w-full md:ml-auto md:max-w-[900px]"
                  >
                    <NoRewardsReceived data={value} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AirdropChecker;
