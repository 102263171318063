import React from 'react';
import { airdropDateFormatter } from '../../../context/dashboard/airdropDataFormatter';
import { eligibilityAssets } from '../../../constants/assets';

function RewardsReceived({ data, primEssence }) {
  const { date, time } = airdropDateFormatter(data.date);

  const formattedAmount = data.reward.amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div className="w-full rounded-lg bg-dashboard_box_blue drop-shadow-lg md:pb-[40px]">
      {/* Desktop UI from 769px */}
      <div className="relative top-[-2px] hidden h-[40px] items-center justify-center font-objektiv md:flex md:h-[50px]">
        <div className="absolute left-0 w-[105%] sm:w-[103%]">
          <img
            className="h-[40px] w-full md:h-[50px]"
            src={eligibilityAssets.greyBannerTop}
            alt="bg-img"
          />
        </div>
        <p className="absolute left-2 text-xs tracking-wide sm:text-sm sm:font-bold md:left-4 md:text-base lg:text-lg">
          AIRDROP DISTRIBUTED
        </p>
        <div className="absolute right-0 flex w-[200px] items-center justify-center md:right-0 md:w-[230px] lg:w-[260px]">
          <img className="w-full" src={eligibilityAssets.timeBg} alt="bg-img" />
          <div className="white absolute flex flex-row text-xs tracking-[2px] md:text-sm lg:text-base">
            <div>{time}</div>
            <div className="mx-1 my-[3px] border-r opacity-50"></div>
            <div className="flex flex-row">
              <p className="pl-[2px]">{date}</p>
              <p className="ml-1 opacity-50">UTC</p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="mx-3 md:mx-8 md:my-6 md:flex md:flex-row">
        <div className="w-full">
          <div className="white flex flex-col pt-4 font-skolar md:mt-0">
            <p className="w-36 text-2xl font-bold tracking-[2px] md:text-3xl">
              {data.eligibilityText}
            </p>
            <p className="my-2 pr-2 font-objektiv tracking-wider md:mb-0 md:pr-12 md:text-lg">
              {data.eligibilityCriteriaText}
            </p>
          </div>
        </div>

        <div className="border-b border-white opacity-50 md:hidden"></div>

        <div className="white mt-2 w-full font-objektiv tracking-wider md:mt-0">
          <div className="mb-2 md:text-lg">
            <p>Rewards:</p>
          </div>
          <div className="border-opacity/20 flex flex-row items-center border border-white bg-dark_blue p-2 md:h-[60px] md:pl-4">
            <div className="border-opacity/20 mr-2 size-[25px] border border-white md:mr-4 md:size-[35px]">
              <div>
                <img src={primEssence} alt="PrimEssence" />
              </div>
            </div>
            <div className="flex flex-col text-xs leading-4 md:text-sm">
              <p>{data.reward.name}</p>
              <p className="opacity-50">{formattedAmount}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile UI until 769px */}
      <div className="relative top-1 mt-6 flex h-[40px] items-center justify-center font-objektiv md:hidden">
        <div className="absolute left-0 w-[105%] sm:w-[103%]">
          <img
            className="h-[40px] w-full"
            src={eligibilityAssets.greyBannerBottom}
            alt="bg-img"
          />
        </div>
        <p className="absolute left-2 text-xs font-bold tracking-wide sm:text-sm">
          Airdrop distributed
        </p>
        <div className="absolute right-0 flex w-[190px] items-center justify-center">
          <img className="w-full" src={eligibilityAssets.timeBg} alt="bg-img" />
          <div className="white absolute flex flex-row text-xs tracking-[1px]">
            <div>{time}</div>
            <div className="mx-1 my-[3px] border-r opacity-50"></div>
            <div className="flex flex-row">
              <p className="pl-[2px]">{date}</p>
              <p className="ml-1 opacity-50">UTC</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardsReceived;
