import React, { useEffect, useState } from 'react';
import { Link, useHref } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import MobileBurgerMenu from './MobileBurgerMenu';
import { navBarSelector } from '../../redux/slices/navbar.slice';
import { ctaData } from './shared/CtaData';

function NavBar({ click, count }) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [nestedMenuOpen, setNestedMenuOpen] = useState(false);
  const [isAccountHovered, setAccountHovered] = useState(false);
  const { isLoggedIn } = useSelector(navBarSelector);

  const { pathname } = window.location;

  useEffect(() => {
    const hamburgerCheckbox = document.getElementById('hamburger');
    if (hamburgerCheckbox) {
      hamburgerCheckbox.checked = false;
      ctaData.link.forEach((link, idx) => {
        setTimeout(() => {
          const item = document.getElementById(`navs${link.id}`);
          if (item) {
            item.classList.add('fade-in-1');
            item.style.opacity = '1';
          }
        }, 150 * idx);
      });
    }
  }, [count]);

  // -----------------------------

  const HrefPath = useHref();

  function toggleMobileMenu() {
    setMobileMenuOpen(!isMobileMenuOpen);
  }

  function toggleNestedMenu(event) {
    event.stopPropagation();
    setNestedMenuOpen(!nestedMenuOpen);
  }
  return (
    pathname !== '/terms'
    && pathname !== '/privacy'
    && pathname !== '/eula'
    && pathname !== '/privacy-policy'
    && pathname !== '/cookies' && (
      <div
        className={`${HrefPath === '/sign-up' ? 'hidden' : ''} ${HrefPath === '/sign-in' ? 'hidden' : ''} ${HrefPath === '/create-username' ? 'hidden' : ''} ${HrefPath === '/legacy-create-username' ? 'hidden' : ''}`}
      >
        <div className="fixed top-0 z-[100] hidden h-[60px] w-full bg-newNavDarkBlue text-center font-skolar font-bold uppercase leading-7 tracking-[3px] lg:flex lg:items-center lg:text-xl xl:text-2xl 2xl:text-3xl">
          <div className="flex flex-row text-left font-black">
            {ctaData.link
              .filter((anchor) => anchor.section === 'signup')
              .map((anchor) => (
                <Link
                  to={anchor.url}
                  className={'ml-11 flex w-[50px] items-center bg-fabLogo bg-contain bg-center bg-no-repeat opacity-0'}
                  key={anchor.id}
                  id={`navs${anchor.id}`}
                  onClick={click}
                  data-link={anchor.section}
                >
                </Link>
              ))}
            {ctaData.link
              .filter((anchor) => anchor.section !== 'signup')
              .map((anchor) => (
                <Link
                  to={anchor.url}
                  className={'ml-10 text-white no-underline transition-all duration-300 ease-out hover:text-[#fcc96b] hover:transition-all hover:duration-300 hover:ease-in'}
                  key={anchor.id}
                  id={`navs${anchor.id}`}
                  onClick={click}
                  data-link={anchor.section}
                >
                  {anchor.label}
                </Link>
              ))}
            <div className="absolute right-14">
              <a
                className="fade-in-1"
                href={isLoggedIn ? '/dashboard' : '/sign-in'}
              >
                {!isLoggedIn ? (
                  <div className=" text-white transition-all duration-300 ease-in hover:text-[#fcc96b]">SIGN IN</div>
                ) : (
                  <div
                    className="flex transition-all"
                    onMouseEnter={() => setAccountHovered(true)}
                    onMouseLeave={() => setAccountHovered(false)}
                  >
                    <div className={twMerge(
                      'duration-300 ease-in',
                      isAccountHovered ? 'text-[#fcc96b]' : 'text-white',
                    )}>ACCOUNT</div>
                    <div
                      className={twMerge(
                        'w-[32px] h-[16px] mt-[7px] bg-contain bg-center bg-no-repeat duration-300 ease-in xl:h-[20px] 2xl:h-6',
                        isAccountHovered ? 'bg-accountImgHovered' : 'bg-accountImg',
                      )}
                    ></div>
                  </div>
                )}
              </a>
            </div>
          </div>
        </div>

        <div
          className="fixed z-50 flex h-[60px] w-full items-center justify-end bg-newNavDarkBlue lg:hidden"
          id="hamburger"
        >
          <MobileBurgerMenu
            toggleMobileMenu={toggleMobileMenu}
            toggleNestedMenu={toggleNestedMenu}
            setNestedMenuOpen={setNestedMenuOpen}
            isMobileMenuOpen={isMobileMenuOpen}
            nestedMenuOpen={nestedMenuOpen}
            isLoggedIn={isLoggedIn}
            HrefPat={HrefPath}
            click={click}
          />
        </div>
      </div>
    )
  );
}

export default NavBar;
