import isValidVariable from './isValidVariable';

const hasWebpVersion = (media, size) => {
  let hasWebp = false;
  if (isValidVariable(media)) {
    if (isValidVariable(media.formats)) {
      if (isValidVariable(media.formats[size])) {
        if (isValidVariable(media.formats[size].webp)) {
          if (isValidVariable(media.formats[size].webp.url)) {
            hasWebp = true;
          }
        }
      }
    }
  }
  return hasWebp;
};

export default hasWebpVersion;
