import { createContext, useReducer } from 'react';
import cookieReducer from './CookieReducer';

const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const initialState = {
    visible: '',
  };

  const [state, dispatch2] = useReducer(cookieReducer, initialState);

  return (
    <CookieContext.Provider
      value={{
        ...state,
        dispatch2,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};
export default CookieContext;
