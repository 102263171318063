import isValidVariable from './isValidVariable';

const outputImageHeight = (media) => {
  let value = 0;
  if (isValidVariable(media)) {
    if (isValidVariable(media.height)) {
      value = media.height;
      if (isValidVariable(media.formats)) {
        if (isValidVariable(media.formats.small)) {
          if (isValidVariable(media.formats.small.height)) {
            value = media.formats.small.height;
          }
        }
      }
    }
  }
  return value;
};

export default outputImageHeight;
