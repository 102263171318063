export const ctaData = {
  link: [
    {
      id: 1,
      internalLink: true,
      label: 'Home',
      url: '/',
      section: 'signup',
    },
    {
      id: 2,
      internalLink: true,
      label: 'Pixion',
      url: '/#pixion',
      section: 'pixion',
    },
    {
      id: 3,
      internalLink: true,
      label: 'Whitepaper',
      url: '/whitepaper',
    },
    {
      id: 4,
      internalLink: true,
      label: 'Quests',
      url: '/quests',
    },
    {
      id: 5,
      internalLink: true,
      label: 'Forge',
      url: '/forge',
    },
    // {
    //   id: 5,
    //   internalLink: true,
    //   label: 'Boss Battle',
    //   url: '/referrals',
    // },
  ],
};
