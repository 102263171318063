import '../../styles/about.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { PIXION_LINK } from '../../constants/index';
import { homePageAssets } from '../../constants/assets';

function About({ data }) {
  return (
    <div className="relative block h-[1000px] bg-aboutPageBackground md:h-[800px] lg:h-[900px] 2xl:h-[1100px]" id="pixion">
      <img src={homePageAssets.aboutBg} alt="pixion" className="absolute bottom-0 left-0 w-full" />
      <div className="relative top-[29%] flex max-w-full flex-col items-center justify-center md:top-[37%] lg:top-[35%] xl:top-[30%] 2xl:top-1/4">
        <h1 className="title">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            SUPPORTED BY
          </AnimationOnScroll>
        </h1>

        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          <div className="about-logos__container">
            {data?.logos?.map((logo, id) => (
              <div key={id} className="about-logos__wrapper">
                <img
                  className="about-logos__img"
                  key={logo.id}
                  src={logo.logo}
                  alt={logo.name}
                  width="100"
                  height="100"
                />
              </div>
            ))}
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          <a href={PIXION_LINK} className='more-info__btn'>
            <img src={homePageAssets.moreInfoBtn} alt="more-info-btn" />
          </a>
        </AnimationOnScroll>
      </div>
    </div>
  );
}

export default About;
