import isValidVariable from './isValidVariable';
import setAssetDomain from './setAssetDomain';

const outputImageWebpSrcset = (media, sizes) => {
  const srcset = [];
  const defaultSizes = {
    massive: 2000,
    xlarge: 1600,
    large: 1000,
    medium: 750,
    small: 500,
  };
  if (isValidVariable(media)) {
    if (isValidVariable(media.formats)) {
      for (let i = 0; i < sizes.length; i += 1) {
        if (isValidVariable(media.formats[sizes[i]])) {
          if (isValidVariable(media.formats[sizes[i]].webp)) {
            srcset.push(
              `${setAssetDomain(media.formats[sizes[i]].webp.url)} ${
                defaultSizes[sizes[i]]
              }w`,
            );
          }
        }
      }
    }
  }
  return srcset.join(', ');
};

export default outputImageWebpSrcset;
