import { twMerge } from 'tailwind-merge';
import { DEFAULT_TOP_REWARDED } from '../../../constants';

export const GrandPrizeBadge = ({ isEligible = false, topRewarded = DEFAULT_TOP_REWARDED }) => (
  <section className="xxs:text-md mt-2 w-full text-sm sm:text-lg 2xl:w-[95%]">
    <div className={
      twMerge(
        'p-2 rounded border-[1px] border-b-4',
        isEligible
          ? 'bg-[#50D500] text-black border-black'
          : 'bg-black text-[#FFDC30] border-[#FFDC30]',
      )}>
      {!isEligible && 'NOT '}
      ELIGIBLE FOR GRAND PRIZE
    </div>
    <div className='rounded rounded-t-none bg-box_dark_blue px-4 py-3 text-sm font-medium xxs:text-base'>
      {`Only the Top ${topRewarded.toLocaleString()} Bronze Leaderboard rank users will be eligible for the Grand Prize.`}
    </div>
  </section>
);
