const isValidVariable = (_variable) => {
  let valid = false;
  if (typeof _variable !== 'undefined') {
    if (_variable !== 'null' && _variable !== null) {
      if (_variable !== '') {
        valid = true;
      }
    }
  }
  return valid;
};

export default isValidVariable;
