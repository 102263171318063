import { Box } from '../shared/Box';
import { authAssets } from '../../../constants/assets';
import { urls } from '../../../constants/urls';
import { SignInBox } from '../shared/SignInBox';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const AdditionalFeaturesSection = ({
  isDiscordLinked,
  discordDisconnect,
  username,
  pixionId,
}) => (
  <Box label="LINK TO ACCESS ADDITIONAL FEATURES">
    <div className='flex flex-1 flex-row justify-between'>
      <SignInBox
        label="Discord"
        isAccountLinked={isDiscordLinked}
        disconnectHandler={discordDisconnect}
        logoUrl={authAssets.discordLogoBlue}
        linkUrl={`${baseUrl}/${urls.discordLogin}?id=${pixionId}`}
        email={username}
      />
    </div>
  </Box>
);
