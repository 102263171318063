import { twMerge } from 'tailwind-merge';
import { QuestStateEnum } from '../../../../enums';
import { handleDashboardRequest } from '../../../../context/dashboard/handleDashboardRequest';
import { errorsMap } from '../../../../errors';
import { ONE_SECOND_IN_MS } from '../../../../constants/time';
import { QUEST_STATE_MAPPER } from '../../../../constants';

const questImageStateMapper = {
  [QuestStateEnum.ACTIVE]: 'bg-greenBtn',
  [QuestStateEnum.COMPLETED]: 'bg-yellowBtnSmall',
};

export const QuestsListButton = ({
  quest, seasonId, confirm, onClaim, onComplete,
}) => {
  const hasTypeTwitter = quest.type.includes('TWITTER');
  const hasTypeDiscord = quest.type.includes('DISCORD');

  const handleClick = async () => {
    const { data: userData } = await handleDashboardRequest();

    if (hasTypeTwitter && !userData.twitterAccountLinked) {
      const agree = await confirm({
        message: errorsMap.get('TWITTER_NOT_LINKED'),
        title: 'LINK X ACCOUNT',
      });

      if (agree) {
        window.location.href = '/dashboard';
      }
    } else if (!userData.walletAddress) {
      const agree = await confirm({
        message: 'You must connect and SIGN your Wallet to start social quests',
        title: 'CONNECT YOUR WALLET',
      });

      if (agree) {
        window.location.href = '/dashboard';
      }
    } else if (hasTypeDiscord && !userData.discordAccountLinked && userData?.walletAddress) {
      const agree = await confirm({
        message: errorsMap.get('DISCORD_NOT_LINKED'),
        title: 'CONNECT YOUR DISCORD',
      });
      if (agree) {
        window.location.href = '/dashboard';
      }
    } else if (quest?.state === QuestStateEnum.COMPLETED) {
      onClaim({ seasonId, questId: quest.id });
    } else if (quest?.state === QuestStateEnum.ACTIVE) {
      const agree = await confirm({
        message: quest.description,
        title: quest.title,
      });

      if (agree) {
        const { url } = quest.typeData;
        if (!url) return;

        window.open(url, '_blank');

        setTimeout(() => {
          onComplete({ seasonId, questId: quest.id });
        }, ONE_SECOND_IN_MS * 5);
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={twMerge(
        'text-shadow-custom relative flex h-[30px] w-20 items-center justify-center bg-contain bg-no-repeat md:h-[36px] md:w-24',
        questImageStateMapper[quest?.state],
      )}
    >
      <p className='absolute tracking-wide drop-shadow-lg'>
        {QUEST_STATE_MAPPER[quest?.state]}
      </p>
    </button>
  );
};
