export const PointsBox = ({
  resource, resourceName, essence,
}) => (
  <li className="relative mb-4 flex flex-row items-center">
    <img
      src={essence}
      alt="gem-icon"
      className="absolute -left-3 top-[-10px] w-[50px] md:w-[55px]"
    />
    <div className="ml-3 flex w-[125px] flex-row items-center rounded bg-black py-1 pl-[25px] pr-2 font-black text-white sm:pl-8 sm:pr-4 md:w-[200px] md:text-lg">
      {resource?.toLocaleString()}
    </div>
    <p className="ml-3 text-sm font-black tracking-wide sm:text-base md:text-lg">
      {resourceName}
    </p>
  </li>
);
