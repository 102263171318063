import { questsAssets } from '../../../constants/assets';

export const SeasonEnd = () => (
  <section className='bg-questsBg bg-cover bg-center bg-no-repeat'>
    <div className='flex h-screen flex-col items-center justify-center backdrop-blur-sm'>
      <div className='relative flex justify-center'>
        <img
          width='600'
          height='380'
          src={questsAssets.logo}
          className='-mt-15 w-[500px]'
        />
        <h1 className='font-poppins` absolute bottom-10 z-10 text-2xl font-black tracking-wider text-white'>
          Season ended
        </h1>
      </div>
    </div>
    <img src={questsAssets.inactiveScreenDivider} alt="bottom-line" />
  </section>
);
