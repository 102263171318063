import { QuestStateEnum } from '../../../enums';
import { useConfirmPopUp } from '../../../hooks/useConfirmPopUp';
import { ClaimedQuestsAccordion } from './ClaimedQuestsAccordion';
import { QuestListItem } from './list-item';

const questsToDisplay = [QuestStateEnum.ACTIVE, QuestStateEnum.COMPLETED];

export const QuestsList = ({
  quests, isDaily, onRewardClaim, ...props
}) => {
  const [PopUp, confirm] = useConfirmPopUp();

  const { unclaimedQuests, claimedQuests } = quests.reduce(
    (acc, quest) => {
      if (questsToDisplay.includes(quest.state)) {
        acc.unclaimedQuests.push(quest);
      } else {
        acc.claimedQuests.push(quest);
      }

      return acc;
    },
    {
      unclaimedQuests: [],
      claimedQuests: [],
    },
  );

  return (
    <>
      <ul className="mb-4 flex w-full flex-col gap-4">
        {unclaimedQuests.length
          ? unclaimedQuests?.map((quest) => (
            <QuestListItem
              quest={quest}
              isDaily={isDaily}
              key={quest.id}
              confirm={confirm}
              {...props}
            />
          ))
          : (
            <div className='rounded bg-inner_box_blue py-6 text-center text-xl font-black text-lightBlue'>
              Please check back soon for more exciting quests
            </div>
          )
        }
      </ul>
      <ClaimedQuestsAccordion quests={claimedQuests} label={isDaily ? 'daily' : 'one-time'} />
      <PopUp />
    </>
  );
};
