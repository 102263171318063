import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import {
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { Provider } from 'react-redux';
import { mainnet } from 'viem/chains';
import { CookieProvider } from './context/cookie/CookieContext';
import Home from './pages/Home';
import { store } from './redux/store';
import { config } from './config/rainbowKitConnectConfig';

const queryClient = new QueryClient();

function App() {
  return (
    <CookieProvider>
      <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            initialChain={mainnet}
            theme={darkTheme({
              borderRadius: 'small',
              overlayBlur: 'small',
            })}
          >
            <Home />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
      </Provider>
    </CookieProvider>
  );
}

export default App;
