import { useMutation, useQuery } from '@tanstack/react-query';
import { instance } from '../../instance';
import { urls } from '../../../constants/urls';
import { seasonInitialState } from '../../../redux/slices/season.slice';

const getSeason = async () => {
  const {
    data: { season },
  } = await instance.get(`/${urls.season}`);

  return season;
};

const getQuests = async (seasonId) => {
  const {
    data: quests,
  } = await instance.get(`/${urls.quests(seasonId)}`);

  return quests;
};

const claimOneTimeQuest = async ({ seasonId, questId }) => {
  const {
    data: { quest },
  } = await instance.post(`/${urls.claimOneTimeQuest({ seasonId, questId })}`);

  return quest;
};

const completeOneTimeQuest = async ({ seasonId, questId }) => {
  const {
    data: { quest },
  } = await instance.post(`/${urls.completeOneTimeQuest({ seasonId, questId })}`);

  return quest;
};

const claimDailyQuest = async ({ seasonId, questId }) => {
  const {
    data: { quest },
  } = await instance.post(`/${urls.claimDailyQuest({ seasonId, questId })}`);

  return quest;
};

const completeDailyQuest = async ({ seasonId, questId }) => {
  const {
    data: { quest },
  } = await instance.post(`/${urls.completeDailyQuest({ seasonId, questId })}`);

  return quest;
};

export const useSeason = () => useQuery({
  queryKey: [urls.season],
  queryFn: getSeason,
});

export const useQuests = (seasonId) => useQuery({
  queryKey: [urls.quests(seasonId)],
  queryFn: () => getQuests(seasonId),
  enabled: seasonId !== seasonInitialState.id,
});

export const useClaimOneTimeQuest = ({ onSuccess }) => useMutation({
  mutationFn: (seasonId, questId) => claimOneTimeQuest(seasonId, questId),
  onSuccess,
});

export const useCompleteOneTimeQuest = ({ onSuccess }) => useMutation({
  mutationFn: (seasonId, questId) => completeOneTimeQuest(seasonId, questId),
  onSuccess,
});

export const useClaimDailyQuest = ({ onSuccess }) => useMutation({
  mutationFn: (seasonId, questId) => claimDailyQuest(seasonId, questId),
  onSuccess,
});

export const useCompleteDailyQuest = ({ onSuccess }) => useMutation({
  mutationFn: (seasonId, questId) => completeDailyQuest(seasonId, questId),
  onSuccess,
});
