import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { setCookieConsentValue } from '../context/cookie/CookieActions';
import NavBar from '../components/general/NavBar';
import Cookie from '../components/general/Cookie';
import CookieContext from '../context/cookie/CookieContext';
import { useContent } from '../services/api/content';
import { websiteContent } from '../constants/content';
import AppRoutes from '../routes/AppRoutes';

const additionalUrls = [
  websiteContent.eula,
  websiteContent.privacy,
  websiteContent.terms,
  websiteContent.footer,
];

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 2000);
  }, [hash]);
};

function Home() {
  const [count, setCount] = useState(0);
  const { data: [eula, privacy, terms, footer] } = useContent(additionalUrls);

  const { dispatch2 } = useContext(CookieContext);

  useEffect(() => {
    const cookieValue = setCookieConsentValue();
    dispatch2({ type: 'SET_COOKIE', payload: cookieValue });
  }, [window.location.href]);

  const goTo = (e) => {
    const element = e.currentTarget.dataset.link;
    setCount((prevState) => prevState + 1);
    if (element) {
      document.getElementById(element).scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <div id="top-of-page">
      <Router>
        <NavBar
          click={goTo}
          count={count}
        />
        <ScrollToHash />
        <AppRoutes terms={terms} eula={eula} privacy={privacy} footer={footer} />
      </Router>
      <Cookie />
    </div>
  );
}

export default Home;
