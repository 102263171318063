import { DateTime } from 'luxon';
import { errorsMap } from '../errors';
import { APPLE_DEVICES, DATE_SUFFIX_MAP, DATE_SUFFIX_EXCEPTIONS } from '../constants';
import { TournamentStateEnum } from '../enums';

export const shortifyWallet = (wallet) => `${wallet?.slice(0, 4)}…${wallet?.slice(-4)}`;

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });

  if (DATE_SUFFIX_EXCEPTIONS.includes(day)) {
    return `${day}th ${month}`;
  }

  const nthNumber = (number) => DATE_SUFFIX_MAP[number % 10] || 'th';

  return `${month} ${day}${nthNumber(day)}`;
};

export const errorMessageMap = (e) => errorsMap.get(e?.response?.data?.error?.message)
  ?? e?.response?.data?.error?.message
  ?? errorsMap.get(e?.response?.data?.error?.code)
  ?? 'Something went wrong';

export const truncateLongString = (str, length = 30) => (str.length >= length ? `${str?.slice(0, 27)}…` : str);

export const getTournamentState = (playToAirdrop) => {
  const currentDate = DateTime.now();
  const startDate = DateTime.fromISO(playToAirdrop?.startDate);
  const endDate = DateTime.fromISO(playToAirdrop?.endDate);

  if (currentDate < startDate) {
    return TournamentStateEnum.COMING;
  } if (currentDate >= startDate && currentDate <= endDate) {
    return TournamentStateEnum.ACTIVE;
  } if (currentDate > endDate) {
    return TournamentStateEnum.INACTIVE;
  }

  return '';
};

export const isAppleDevice = () => APPLE_DEVICES.includes(navigator?.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
